.m-video-progress-bar {
  .m-bar-container {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    display: inline-block;
    position: relative;
    width: 100%;
    font-size: 14px;

    .m-bar-outer {
      display: inline-block;
      width: 100%;
      margin-right: 0;
      padding-right: 0;

      .m-bar-inner {
        position: relative;
        display: flex;
        width: 100%;
        background-color: #70707050;
        border-radius: 10px;

        .m-bar-progress {
          height: 8px;
          position: relative;
          background-color: $blue-main;
          border-radius: 10px;
        }

        .m-progress-icon {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          position: absolute;
          background-color: $blue-main;
          top: -7px;
        }

        .m-progress-input {
          position: absolute;
          width: 102%;
          top: -5px;
          left: -11px;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }
}
