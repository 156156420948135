.zh-scroll {
  position: relative;
  max-height: rem(1100px);
  overflow: hidden auto;

  & .zh-checkbox {
    outline: 0;
    user-select: none;
    margin-bottom: 0 !important;

    & label {
      width: 100%;
      padding: rem(10px 0);
    }
  }

  & .zh-checkbox:not(:last-child) label {
    border-bottom: 1px solid #dfddd6;
  }

  &.hidden {
    @include hidden-scroll;
    padding: rem(10px 0);
  }

  &.scrollbar-holder {

  }
}
