.audio-player {
  position: relative;
  background-color: $white;

  .rhap_main-controls {
    display: flex;
    justify-content: center;
    gap: rem(20px);
  }

  .rhap_main-controls-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    svg {
      width: rem(30px);
      height: rem(30px);
      color: $dark-blue;
    }
  }

  .rhap_play-pause-button {
    background-color: $blue-main;
    border-radius: 100%;
    width: rem(66px);
    height: rem(66px);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: rem(26px);
      height: rem(26px);
      color: $white;

      &.play {
        margin-left: rem(3px);
      }
    }
  }

  .rhap_progress-container {
    height: rem(4px);
    width: 100%;
    position: absolute;
    top: rem(5px);
    cursor: pointer;
  }

  .rhap_progress-bar {
    height: rem(4px);
    background-color: $text-color-grey-5;
  }

  .rhap_progress-indicator {
    width: rem(8px);
    height: rem(8px);
    background-color: $blue-main;
    border-radius: 100%;
    position: absolute;
    z-index: 10;
    top: rem(-2px);
  }

  .rhap_progress-filled {
    background-color: $blue-main;
    height: rem(4px);
    position: absolute;
    z-index: 9;
  }

  .rhap_download-progress {
    background-color: $text-color-grey-25;
    height: rem(4px);
    position: absolute;
  }

  .rhap_progress-section {
    position: relative;
    padding-top: rem(25px);
    height: rem(36px);
    display: flex;
    justify-content: space-between;
  }

  .rhap_time {
    font-size: rem(14px);
    color: $text-color;
  }

  .rhap_volume-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: calc(50% + 7px);
    left: 0;

    svg {
      width: rem(25px);
      height: rem(25px);
      color: $text-color;
    }
  }

  .rhap_volume-controls {
    position: absolute;
    height: 100%;
    top: 0;
    width: rem(45px);

    &:hover .rhap_volume-bar-area {
      display: block;
    }

    .rhap_volume-bar-area {
      display: none;
      width: 125%;
      height: rem(12px);
      position: absolute;
      top: calc(50% + 14px);
      left: rem(40px);
      padding: rem(5px 0);
    }

    .rhap_volume-bar {
      cursor: pointer;
      background-color: $text-color;
      width: 100%;
      height: rem(2px);
    }

    .rhap_volume-indicator {
      background-color: $blue-main;
      border-radius: 100%;
      position: absolute;
      top: rem(3px);
      width: rem(6px);
      height: rem(6px);
      z-index: 9;
      cursor: pointer;
    }
  }
}