.comment-block {
  display: flex;
  border-radius: 16px;
  box-shadow: 0 4px 24px $dark-10;
  height: rem(148px);
  background-color: $white;
  margin-bottom: rem(20px);
  padding: rem(16px);

  &__image {
    img {
      width: rem(116px);
      height: rem(116px);
      object-fit: cover;
      border-radius: 16px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: rem(9px 29px 9px 20px);
    width: 100%;
  }

  &__wrapper {
    width: 90%;
  }

  &__title {
    font-size: rem(14px);
    margin-bottom: rem(16px);
  }

  &__program-title {
    font-size: rem(18px);
    color: $dark-blue;
    @include switzer-semibold;
    margin-bottom: rem(6px);
    @include text-ellipses(2);
  }

  &__description {
    line-height: rem(18px);
    font-size: rem(14px);
    color: $dark-blue;
    @include text-ellipses(2);
  }


  &__button {
    width: rem(36px);
    height: rem(36px);
    margin: auto 0;

    svg {
      color: $dark-blue;
      width: rem(36px);
      height: rem(36px);
      padding: rem(6px);
      background-color: $bg-blue-40;
      border-radius: 100%;
      cursor: pointer;
    }
  }
}