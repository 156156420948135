.switch-devices-tooltip {
  width: rem(250px);
  padding-bottom: rem(20px);
  z-index: 9999;

  &__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(10px);
    margin-bottom: rem(20px);

    span {
      color: $dark-blue;
    }

    svg {
      width: rem(100px);
      height: rem(100px);
      color: $dark-blue;
    }
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    padding: rem(10px);
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #F1F3FA;
    }

    &__active {
      font-size: rem(14px);
    }

    &__this-device {
      font-size: rem(14px);
    }

    &__name {
      color: $dark-blue;
      font-size: rem(14px);
    }

    &__icon {
      width: rem(24px);
      height: rem(24px);
      svg {
        width: rem(24px);
        height: rem(24px);
      }
    }
  }
}