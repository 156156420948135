.m-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  &__text {
    margin-top: rem(20px);
    @include switzer-semibold;
    font-size: rem(18px);
    line-height: rem(22px);
    color: $text-color-grey-600;
  }
}
