.search-page {
  &__mini-title {
    margin-top: rem(30px);
  }

  &__title {
    font-size: rem(30px);
    line-height: rem(38px);
  }
}

@media (max-width: $grid-sm-size + 36) {
  .search-page {
    &__mini-title {
      margin-top: 0;
      margin-bottom: rem(16px);
    }
  }
}