.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;

  &.ant-progress-line {
    position: relative;
    width: 100%;
    font-size: 14px;
    margin-right: 8px;
    margin-bottom: 8px;

    @include local-mq($until: md) {
      margin: 0;
    }
  }

  &.ant-progress-status-normal {
  }

  &.ant-progress-default {
  }

  .ant-progress-outer {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0;

    .ant-progress-inner {
      position: relative;
      display: flex;
      width: 100%;
      vertical-align: middle;
      border-radius: 4px;
      background-color: $blue-border-color;

      .ant-progress-bg {
        width: 0;
        height: 4px;
        position: relative;
        background-color: $blue-main;
        border-radius: 4px;
        -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
        transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;

        @include local-mq($from: qhd) {
          height: rem(8px);
        }
      }

      .m-progress-icon {
        position: absolute;
        top: 12px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 7px solid $blue-main;
      }
    }
  }
}
