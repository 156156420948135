.qa-details {
  .ebs-chips {
    @include flex-center;
    pointer-events: none;
    color: $blue-main;
    font-weight: 500;
    font-size: rem(14px);
    height: rem(25px);
    padding: rem(3.5px 10px);
    background-color: #f2f3f6;
    border: 1px solid #fff;

    &:hover {
      color: $dark-blue;
      border: 1px solid $dark-blue;
      background-color: #fff;
    }
  }

  .ebs-collapse {
    margin-top: rem(32px);
    box-shadow: 0px 4px 24px rgba(5, 41, 65, 0.1);
    border-radius: 16px;
    border: none;

    .ebs-collapse__header {
      @include flex-center-vert;
      height: rem(71px);
      padding: rem(0 32px);
      border: none;
      font-size: rem(18px);
      font-weight: 600;

      @include local-mq($until: lg) {
        height: rem(50px);
        padding: rem(0 20px);
      }
    }

    .ebs-collapse__body {
      background-color: #fff;
      padding: rem(6px 34px 40px);

      @include local-mq($until: lg) {
        padding: rem(0 20px 23px);
      }
    }
  }

  .ebs-collapse__header {
    .ebs-collapse__header__toggle {
      svg {
        display: none;
      }

      &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.08187 9.00016L10.6019 15.5202C11.3719 16.2902 12.6319 16.2902 13.4019 15.5202L19.9219 9.00016' stroke='%232660F6' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        width: rem(24px);
        height: rem(24px);
        transform: rotate(180deg);
        transition: all 300ms;
      }
    }

    &.ebs-collapse__header--collapsed .ebs-collapse__header__toggle::before {
      transform: rotate(0);
    }
  }

  .qa-banner {
    margin-top: rem(35px);
  }

  .app-404 {
    min-height: rem(250px);

    .app-404-left {
      top: 50%;
    }
  }

  .head-title {
    font-size: rem(30px);
  }

  h3 {
    font-weight: 600;
    font-size: rem(24px);
    margin-top: rem($gutter-size);
  }

  &__container {
    &-data > .ebs-space__item {
      width: 100%;
    }
    &-question {
      font-weight: 500;
      font-size: rem(20px);
      line-height: rem(28px);
      background-color: #f2f3f6;
      border-radius: rem($gutter-size);
      padding: rem(30px 32px);
      margin-top: rem($gutter-size);
      width: 100%;

      &__expand {
        overflow: hidden;
        max-height: 13rem;
        transition: max-height 1s;

        @include local-mq($until: lg) {
          max-height: 21rem;
        }

        &.open {
          max-height: 60vh;
          overflow: auto;
        }
      }

      h3 {
        font-weight: 600;
        font-size: rem(20px);
        line-height: rem(27px);
        margin-top: 0;
      }

      @include local-mq($until: lg) {
        font-size: rem(18px);
        line-height: rem(27px);
        padding: rem(18px 16px);

        h3 {
          font-size: rem(18px);
          line-height: rem(23px);
        }
      }
    }

    &-answer {
      font-size: rem(16px);
      font-weight: 500;
      line-height: rem(25.6px);
    }

    .card-container__wrapper {
      max-width: rem(794px);

      img {
        max-width: 100%;
      }
    }
  }
}

.qa-details-page {
  &,
  body {
    overflow-x: hidden;
  }

  .header .card-container__wrapper > .ebs-space > .ebs-space__item:last-child {
    width: 100%;

    > .ebs-space {
      justify-content: flex-end;

      > .ebs-space__item:first-child {
        @include flex;
        justify-content: flex-end;
        width: 100%;

        .qa-search {
          width: 100%;
          max-width: rem(290px);
          transition: all 300ms;

          &.is-open {
            max-width: 100%;
          }
        }
      }
    }
  }

  .header__search-component {
    @include flex-center;
    height: rem(90px);

    @media (max-width: $grid-sm-size + 36) {
      height: rem(70px);
    }
  }

  .header__search-form__close {
    position: unset;
    margin-left: rem(18px);
    right: unset;
  }

  .header__search-form {
    @extend .card-container__wrapper;
    @include flex-center;
    height: 100%;
    padding: rem(0 18px);

    .qa-search {
      max-width: 100% !important;
    }
  }

  .back-bread-crumbs__text {
    border-bottom: none;
  }
}
