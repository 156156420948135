.confirm-modal {
  .ebs-modal {
    border-radius: 16px;
  }

  .ebs-modal__header {
    border-bottom: none;
    border-radius: 16px 16px 0 0;
  }

  .ebs-modal__title {
    position: relative;
    top: rem(20px);
    font-weight: 600;
    font-size: rem(24px);
    line-height: rem(32px);
    text-align: center;
    color: $dark-blue;
    @include switzer-semibold;
  }

  .ebs-modal__footer {
    border-radius: 0 0 16px 16px;
    border-top: none;
    text-align: center;
    padding-bottom: rem(40px);
  }

  .ebs-button--text .ebs-icon {
    color: $text-color;

    &:hover {
      color: $text-color-grey-75;
    }
  }

  &__content {
    font-size: rem(16px);
    line-height: rem(20px);
    color: $dark-blue;
    text-align: center;
    padding: rem(16px);
  }

  &__button {
    color: $white;
    width: rem(160px);
    line-height: rem(38px);
    border-radius: 8px;
    margin: rem(0 10px);
    padding: rem(5px)
  }

  &__button-cancel.ebs-button--ghost.ebs-button__wrapper {
    background-color: $bg-blue-40;
    border-color: transparent;
    color: $blue-main;

    &:hover {
      background-color: $dark-10;

      button {
        color: $blue-main;
      }
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .confirm-modal {
    padding: rem(0 20px);

    .ebs-button--text .ebs-icon {
      width: rem(18px);
      height: rem(18px);
    }

    .ebs-modal {

      &__content {
        padding: rem(20px 20px 30px);
      }

      &__footer {
        padding: rem(14px 0 35px);
      }
    }
  }
}