.footer {
  background-color: $dark-blue;
  padding: rem(70px 0);
  color: $white;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: rem(60px);
  }

  &__about {
    display: flex;
    flex-direction: column;
    gap: rem(24px);
  }

  &__first-column {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: rem(24px);
  }

  &__logo {
    width: rem(114px);
    height: rem(28px);
    color: $white;
  }

  &__copyright {
    color: #D9DBE1;
  }

  &__meta-description {
    max-width: rem(520px);
    width: 100%;
    display: block;
    font-size: rem(14px);
    @include switzer-light;

    .languages-button {
      display: none;
    }
  }

  &__menu {
    a {
      color: $white;
      font-size: rem(16px);
      @include switzer-light;
    }
  }

  &__app-store {
    float: right;
    margin-bottom: rem(16px);
    display: flex;

    img {
      width: rem(135px);
      border: 1px solid #A6A6A6;
      border-radius: 8px;
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .footer {
    padding: rem(60px 0 120px);

    &__first-column {
      flex-direction: column;
      gap: rem(50px);
    }

    &__wrapper {
      flex-direction: column-reverse;
    }

    &__apps {
      display: flex;
      gap: rem(15px);

      a img {
        width: 100%;
        max-width: rem(160px);
      }
    }

    &__copyright {
      position: absolute;
      bottom: rem(30px);
      width: 100%;
      left: 0;
      text-align: center;
    }

    &__meta-description {
      .languages-button {
        display: inline-block;
      }
    }
  }
}