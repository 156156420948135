.watch-layout {
  &.episode-layout {
    .breadcrumbs {
      margin-bottom: rem(24px);
    }
  }

  &__statistics {
    display: flex;
    gap: rem(30px);
    color: $text-color;
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(18px);

    &__star {
      fill: $white;
      color: $text-color;
      display: flex;
      align-items: center;
      gap: rem(7.5px);
    }

    &__approved-user {
      display: flex;
      align-items: center;
      gap: rem(7.5px);
      color: $text-color;
    }

    &__cup {
      display: flex;
      align-items: center;
      gap: rem(7.5px);
      color: $text-color;
    }

    svg {
      width: rem(18px);
      height: rem(18px);
    }
  }

  &__image {
    position: relative;

    img {
      width: 100%;
      border-radius: 16px;
      object-fit: cover;
      height: rem(403px);
      display: block;
    }

    &__layer {
      background-color: $black;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      border-radius: 16px;
    }

    &__content {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      color: $white;
      padding: rem(30px 70px 30px 30px);
      display: flex;
      flex-direction: column;
      gap: rem(12px);
      justify-content: flex-end;
    }

    &__content-mobile {
      display: none;
    }

    &__content-title {
      @include switzer-bold;
      font-size: rem(30px);
      line-height: rem(38px);
    }

    &__content-description {
      font-weight: 400;
      font-size: rem(14px);
      line-height: rem(18px);
      margin-bottom: rem(15px);
    }

    &__content-author {
      display: flex;
      align-items: center;
      margin-bottom: rem(15px);
      gap: rem(10px);

      & > div {
        @include switzer-light;
      }

      span {
        @include switzer-semibold;
      }
    }

    &__content-author-photo {
      img {
        width: rem(32px);
        height: rem(32px);
        object-fit: cover;
        box-sizing: border-box;
        border: 1px solid $blue-main;
      }
    }

    &__content-button {
      border: none;
      border-radius: 8px;

      &:hover {
        background-color: transparent;
      }

      .ebs-button {
        border-radius: 8px;
        width: rem(343px);
        background-color: $blue-main;
        @include switzer-medium;
        font-size: rem(20px);
        line-height: rem(40px);
        border: none;

        &:hover {
          background-color: $blue-main;
        }
      }
    }
  }

  &__left-side {
    width: rem(410px);
  }

  &__tabs {
    .ebs-tabs {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $blue-border-color;

      &__item {
        flex: 1;
        text-align: center;
        @include switzer-medium;
        font-size: rem(16px);
        margin: 0;

        &.active {
          color: $blue-main;

          .tab-badge {
            background-color: $blue-main;
          }
        }
      }
    }

    &--border {
      border-radius: 16px;
      box-shadow: 0 4px 24px $dark-10;
      margin-top: rem(36px);
      margin-bottom: rem(70px);
    }

    .tab-badge {
      @include switzer-light;
      padding: rem(3px);
      background-color: $text-color;
      color: $white;
      min-width: rem(18px);
      min-height: rem(18px);
      border-radius: 4px;
      font-size: rem(9px);
      display: block;
    }
  }

  &__under-tabs-mobile {
    display: none;
  }

  .ebs-tabs__content {
    padding: 0;
  }

  &__space {
    > .ebs-space__item:first-child {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-title {
    @include switzer-semibold;
    font-size: rem(30px);
    font-weight: rem(38px);
    margin: rem(0 0 8px);
  }

  &__header-subtitle {
    @include switzer-light;
    font-size: rem(14px);
  }

  &__header-buttons {
    display: flex;
    align-items: center;
    gap: rem(10px);

    &--title {
      @include switzer-semibold;
      font-size: rem(14px);
      color: $dark-blue;
    }

    &--left,
    &--right {
      width: rem(36px);
      height: rem(36px);
      background-color: $bg-blue-40;
      border-radius: 50%;
      padding: rem(6px);
      cursor: pointer;

      svg {
        width: rem(24px);
        height: rem(24px);
        color: $dark-blue;
      }

      &:hover {
        box-shadow: 0 0 7px $dark-10;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.disabled:hover {
        box-shadow: none;
      }
    }
  }

  &__audio {
    margin-top: rem(34px);
    height: rem(403px);
    display: flex;
    flex-direction: column-reverse;
    border-radius: 16px;
    box-shadow: $box-shadow;

    &__wrapper {
      padding: rem(10px 20px 20px);
      border-top: 1px solid $border-color;
      background-color: $white;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  &__video {
    margin-top: rem(34px);
    height: rem(403px);

    .video-js-hover-background {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: black;
      visibility: visible;
      opacity: 0;
      transition: visibility 1s, opacity 1s;

      &.show {
        display: flex;
        visibility: visible;
        opacity: 0.9;
        transition: visibility 0.3s, opacity 0.3s;
        transition-timing-function: ease-in-out;
      }
    }

    .video-js-hover-play {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      justify-content: center;
      align-content: center;

      .icon-play {
        width: 100px;
        height: 100px;
        cursor: pointer;
      }

      .ebs-loader > {
        .ebs-loader__spinner {
          left: calc(50% - 35px);
          top: calc(50% - 33px);
        }
      }
    }

    .video-js-hover {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      visibility: visible;
      opacity: 0;
      transition: visibility 1s, opacity 1s;

      .video-actions-container {
        width: 100%;

        .m-video-controls {
          .m-icon {
            @include local-mq($until: sm) {
              height: 30px;
              width: 30px;
            }
          }
        }

        .m-play-holder {
          min-width: 49px;
          min-height: 59px;
          height: 59px;
          width: 49px;
        }

        .m-play-btn {
          width: rem(49px);
          cursor: pointer;
        }
      }

      &.show {
        display: flex;
        visibility: visible;
        opacity: 1;
        transition: visibility 0.3s, opacity 0.3s;
        transition-timing-function: ease-in-out;
      }
    }

    .video-js {
      width: 100%;
      height: rem(403px);
      background-color: #fff;

      @include local-mq($until: sm) {
        height: rem(250px);
      }

      .vjs-poster {
        background-size: cover;
        border-radius: 16px;
      }

      .m-control-bar {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        margin: 0 0 rem(25px) 0;

        @include local-mq($until: sm) {
          margin: 0 0 rem(10px) 0;
        }

        .m-action-mute {
          &.mobile {
            display: none;

            @include local-mq($until: sm) {
              display: block;
              padding-left: 0;
            }
          }

          &.desktop {
            @include local-mq($until: sm) {
              display: none;
            }
          }
        }

        .m-mobile-margin {
          @include local-mq($until: sm) {
            margin-top: 15px;
          }
        }

        .control-bar-text {
          @include switzer-light;
          font-size: rem(16px);
          color: #fefefe;
          display: flex;
          align-items: center;
        }
      }

      &.vjs-fullscreen {
        z-index: 99;
      }
    }

    .vjs-menu-button-popup .vjs-menu {
      z-index: 1;
    }

    &__play {
      color: #fff;
      position: absolute;
      top: calc(50% - 41px);
      left: calc(50% - 41px);
      background-color: #2660f6;
      width: 82px;
      height: 82px;
      border-radius: 50%;
      padding: 26px;
      cursor: pointer;

      svg {
        width: rem(32px);
        height: rem(32px);
      }
    }
  }

  &__episode {
    padding-bottom: rem(36px);
    border-bottom: 1px solid $blue-border-color;

    &__title {
      font-size: rem(21px);
    }

    &__clap-cap {
      display: flex;

      span {
        color: $dark-blue;
        font-size: rem(14px);
      }
    }

    &__clap-wrapper {
      display: flex;
      gap: rem(48px);
    }

    &__clap-wrapper-mobile {
      display: none;
    }

    &__clap-text {
      display: flex;
      color: $dark-blue;
      font-size: rem(14px);

      .attending {
        &__img {
          width: rem(24px);
          height: rem(24px);
        }

        &__icon {
          width: rem(24px);
          height: rem(24px);

          svg {
            width: rem(24px);
            height: rem(24px);
          }
        }
      }
    }

    &__actions {
      color: $dark-blue;

      svg {
        width: rem(17px);
        height: rem(17px);
        cursor: pointer;
      }

      &--message {
        color: $dark-blue;
        fill: $white;
      }
    }

    &__bookmark {
      width: rem(24px);
      height: rem(24px);
      color: $dark-blue;
      cursor: pointer;

      &.bookmarked {
        fill: $dark-blue;

        path:nth-child(n + 2) {
          fill: $white;
          color: $white;
        }
      }
    }
  }
}

@media (max-width: $grid-lg-size + 32) {
  .watch-layout {
    &__tabs--border {
      box-shadow: none;
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .watch-layout {
    &__mobile-hide {
      display: none;
    }

    &__image {
      margin-left: rem(-22px);
      margin-right: rem(-22px);
      height: rem(235px);

      &__layer,
      > img {
        border-radius: 0;
        height: rem(235px);
      }

      &__content-button-wrapper {
        display: none;
      }

      &__mobile-play-button {
        position: absolute;
        top: rem(17px);
        right: rem(17px);
        width: rem(50px);
        height: rem(50px);
        border-radius: 100%;
        background-color: $blue-main;
        padding: rem(15px 16px);

        svg {
          color: $white;
          height: rem(20px);
          width: rem(20px);
          z-index: 999;
        }
      }

      &__content {
        display: none;
      }

      &__content-mobile {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        color: $white;
        padding: rem(30px 70px 30px 30px);
        display: flex;
        flex-direction: column;
        gap: rem(8px);
        justify-content: flex-end;
      }

      &__content-continue {
        @include switzer-light;
        font-size: rem(14px);
        color: $white80;
      }

      &__content-title {
        font-size: rem(16px);
        line-height: rem(22px);
        @include switzer-semibold;
      }
    }

    &__tabs .ebs-tabs {
      margin-left: rem(-22px);
      margin-right: rem(-22px);
    }

    &__under-tabs-mobile {
      display: block;

      &__title {
        font-size: rem(24px);
        margin-top: rem(24px);
      }

      &__description {
        color: $dark-blue;
        line-height: rem(18px);
        margin-top: rem(24px);
        margin-bottom: rem(10px);
      }
    }

    &__episode {
      padding-bottom: rem(10px);

      &__title {
        margin-top: rem(10px);
      }

      &__clap-wrapper-mobile {
        display: flex;
        justify-content: space-between;
        padding-bottom: rem(20px);
        border-bottom: 1px solid $border-color;
        margin-bottom: rem(15px);

        .watch-layout__episode__clap-text {
          display: flex;
        }

        &__messages {
          display: flex;
          align-items: center;
          gap: rem(2px);
          color: $text-color;
        }
      }

      &__clap-text {
        display: none;
      }

      &__actions {
        .ebs-space__item:first-child {
          margin-right: rem(24px) !important;
        }

        svg {
          width: rem(24px);
          height: rem(24px);
        }
      }
    }

    &__video {
      margin-top: 1px;
      margin-left: rem(-22px);
      margin-right: rem(-22px);
      height: rem(210px);

      .video-js {
        height: rem(210px);
      }

      video[poster] {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &__play {
        width: 50px;
        height: 50px;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        padding: 16px 17px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    &__header {
      display: none;
    }

    &__tabs--border {
      box-shadow: none;
      margin-top: 0;
    }
  }

  .episode-page .watch-layout {
    &__tabs--border {
      margin-bottom: 0;
    }
  }
}

.watch-page:not(.episode-page) {
  &,
  body {
    overflow-x: hidden;
  }
}

.episode-page {
  overflow-x: hidden !important;
}
