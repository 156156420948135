.rating {
  &__star {
    fill: $white;
    color: $text-color;
    width: rem(12px);
    height: rem(12px);
  }

  &__star-filled {
    color: $yellow-50;
    fill: $yellow-50;
  }

  &__no-reviews {
    color: $quiz-sub-title;
    font-weight: 600;
    font-size: rem(12px);
    vertical-align: text-top;
  }
}