.account-layout {
  margin-top: rem(70px);
  margin-bottom: rem(70px);
  width: rem(590px);
  box-shadow: 0 4px 70px rgba(5, 41, 65, 0.07);
  border-radius: 20px;
}

@media (max-width: $grid-sm-size + 36) {
  .account-layout {
    margin-top: rem(24px);
    border-radius: 15px;
    box-shadow: 0 20px 40px rgba(205, 212, 225, 0.2);
    width: 94%;
  }
}