.qa-list {
  @include local-mq($from: lg) {
    flex-wrap: nowrap;
    margin-left: calc(#{var(--bs-gutter-x)} / -1) !important;
    margin-right: calc(#{var(--bs-gutter-x)} / -1) !important;
  }

  .prev-item .ebs-card__body {
    transition: background-color 1s;
    animation-duration: 1s;
    animation-name: blink;
  }

  &__bar {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #fff;
    padding-bottom: rem(24px);
    margin-bottom: rem(24px);
    transform: scaleY(0);
    transform-origin: top;
    transition: all 300ms ease-in-out;

    &.is-search {
      padding-top: rem(24px);
    }

    &.is-prev {
      transform: scaleY(1);
    }

    &.is-sticky {
      transform: scaleY(1);
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 1px;
        z-index: -1;
        transform: scale(0.9);
        box-shadow: 0px 0px 8px 2px rgba(5, 41, 65, 0.1);
      }
    }

    &.is-search &-info {
      max-height: 0;
      transform: scaleY(0);
    }

    &-info {
      max-height: rem(200px);
      overflow: hidden;
      transform: scaleY(1);
      transform-origin: top;
      transition: all 300ms ease-in-out;
    }

    @include local-mq($until: lg) {
      margin-left: -15px !important;
      margin-right: -15px !important;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__empty {
    svg {
      height: rem(400px);
      width: 100%;
      margin-bottom: rem(-100px);
    }
  }
}

.qa-list-page {
  .header__search,
  .header__search-component {
    display: none;
  }
}

@keyframes blink {
  0% {
    background-color: #fff;
  }

  50% {
    background-color: #c0d2ff;
  }

  0% {
    background-color: #fff;
  }
}
