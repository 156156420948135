.resources {
  padding: rem(20px 42px);

  &__title {
    color: $dark-blue;
    font-size: rem(18px);
    line-height: rem(24px);
    @include switzer-semibold;
    margin-bottom: rem(16px);

    &:nth-child(3) {
      margin-top: rem(30px);
    }
  }

  &__item {
    padding: rem(10px 0);


    &__files {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: rem(22px);

      svg {
        color: $dark-blue;
        width: rem(24px);
        height: rem(24px);
      }

      a {
        color: $dark-blue;
        font-size: rem(14px);
      }
    }

    &__tasks {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: rem(22px);
      color: $dark-blue;

      svg {
        width: rem(20px);
        height: rem(20px);
      }

      &.done {
        color: $blue-main;
      }
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .resources {
    padding: rem(16px 0 0);

    &__title:last-child {
      margin-top: rem(35px);
    }
  }
}