.qa-search {
  position: relative;

  .ebs-input__clear {
    @include flex-center;
    height: rem(24px);
    width: rem(24px);
    font-size: rem(20px);
    line-height: 0;
    background-color: #99a0b4;
    border-radius: 100%;
    color: #fff;
    right: rem($base-size);

    @include local-mq($until: lg) {
      height: rem(16px);
      width: rem(16px);
      font-size: rem(16px);
      right: rem(16px);
    }
  }

  > .ebs-space {
    > .ebs-space__item:first-child {
      width: 100%;

      @include local-mq($from: lg) {
        margin-right: 0 !important;
      }
    }
  }

  .ebs-button__wrapper {
    border-radius: rem(31px);
    background: rgba(223, 225, 232, 0.4);
  }

  .title {
    color: $dark-blue;
    font-weight: 600;
    font-size: rem(14px);
  }

  &__input {
    background: linear-gradient(0deg, rgba(223, 225, 232, 0.4), rgba(223, 225, 232, 0.4)), #ffffff;
    border: none;
    border-radius: rem(60px);
    height: rem(48px);
    padding-left: rem(24px);

    input {
      font-size: rem(17px);
      height: 100%;

      &::placeholder {
        color: #99a0b4;
      }
    }

    .ebs-input__container {
      height: 100%;
    }

    .ebs-input__prefix {
      left: 0;
      padding-left: rem(34px);
      color: #99a0b4;

      svg {
        color: #99a0b4;
        font-size: rem($gutter-size);
      }
    }

    @include local-mq($until: lg) {
      height: rem(38px);
      padding-left: 0;

      .ebs-input__prefix {
        padding-left: rem(12px);
      }
    }
  }

  &__popup {
    position: absolute;
    margin-top: rem($gutter-size);
    border-radius: rem($gutter-size);
    border: none;
    z-index: 2;

    .ebs-card__body {
      background-color: #fff;
    }

    .ebs-chips {
      @include flex-center;
      color: $dark-blue;
      font-weight: 500;
      font-size: rem(14px);
      height: rem(25px);
      padding: rem(3.5px 10px);
      background-color: #f2f3f6;
      border: 1px solid #fff;

      &:hover {
        color: $dark-blue;
        border: 1px solid $dark-blue;
        background-color: #fff;
      }
    }

    &-close.ebs-button__wrapper {
      position: absolute;
      top: rem(24px);
      right: rem(24px);
      width: rem(24px);
      height: rem(24px);
      background-color: #99a0b4;
      border: none;

      .ebs-button {
        @include flex-center;
        height: 100%;
        font-size: rem(20px);
        line-height: 0;
        padding: 0;

        svg {
          position: absolute;
          color: #fff;
        }
      }

      @include local-mq($until: lg) {
        width: rem($gutter-size);
        height: rem($gutter-size);

        .ebs-button {
          font-size: rem($gutter-size);
        }
      }
    }

    &-tags {
      max-height: rem(125px);
      overflow: hidden;

      @include local-mq($from: qhd) {
        max-height: rem(110px);
      }

      .ebs-chips {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &__mobile-btn {
    @include local-mq($from: lg) {
      display: none;
    }
  }
}
