.my-program {
  margin-bottom: rem(60px);

  &__title::first-letter {
    text-transform: capitalize;
  }

  &__card {
    margin-bottom: rem(50px);
  }
}