.m-icon {
  &.bell {
    .a {
      fill: #fff;
    }
  }

  &.globe {
    margin-top: 5px;

    .a,
    .b {
      fill: none;
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
    .b {
      stroke-linecap: square;
    }
  }

  &.forward {
    .a {
      fill: #919499;
    }
    .a,
    .b {
      stroke: #919499;
      stroke-linecap: round;
      stroke-width: 3px;
    }
    .b {
      fill: none;
    }
  }

  &.back {
    .a {
      fill: #919499;
    }
    .a,
    .b {
      stroke: #919499;
      stroke-linecap: round;
      stroke-width: 3px;
    }
    .b {
      fill: none;
    }
  }

  &.icon-mail {
    .a {
      fill: #5e5e5e;
    }
  }

  &.icon-clock {
    .a {
      fill: #919499;
    }
  }

  &.icon-phone {
    .a {
      fill: #5e5e5e;
    }
    .b {
      fill: #fff;
    }
  }

  &.icon-users {
    .a {
      fill: #919499;
    }
  }

  &.icon-play {
    .a {
      fill: #141414;
      opacity: 0.8;
    }
    .b {
      fill: #fff;
    }
  }

  &.icon-filled-star {
    .a {
      fill: #ffca28;
      stroke: #ffca28;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 1.8px;
    }
  }

  &.icon-star {
    .a {
      fill: none;
      stroke: #909398;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 1.8px;
    }
  }

  &.icon-down {
    .a {
      fill: #141414;
    }
    .a,
    .b {
      stroke: #141414;
      stroke-linecap: round;
      stroke-width: 2px;
    }
    .b {
      fill: none;
    }
  }

  &.icon-claps {
    cursor: pointer;

    .a {
      fill: none;
      stroke: #141414;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 1.8px;
    }
  }

  &.icon-comment {
    cursor: pointer;

    .a {
      fill: none;
      stroke: #141414;
      stroke-width: 1.8px;
    }
    .b {
      fill: #141414;
    }
  }

  &.icon-share {
    .a,
    .b {
      fill: none;
      stroke: #141414;
      stroke-miterlimit: 10;
      stroke-width: 1.8px;
    }
    .b {
      stroke-linecap: square;
      stroke-linejoin: round;
    }
  }

  &.icon-filled-bookmark {
    .a {
      fill: #ffca28;
      stroke: #ffca28;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 1.8px;
    }
  }

  &.icon-bookmark {
    cursor: pointer;

    .a {
      fill: rgba(0, 0, 0, 0);
      stroke: #141414;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 1.8px;
    }
  }

  &.icon-checked {
    .a {
      fill: #3478f6;
      stroke: #3478f6;
      fill-rule: evenodd;
    }
  }

  &.icon-done-circle {
    .a {
      fill: #3478f6;
      stroke: #3478f6;
    }
    .b {
      fill: #fff;
    }
    .c {
      stroke: none;
    }
    .d {
      fill: none;
    }
  }

  &.icon-search {
    .a {
      fill: #fff;
    }
  }

  &.icon-close {
    cursor: pointer;

    .a {
      fill: none;
      stroke: #141414;
      stroke-linecap: round;
      stroke-width: 2px;
    }
  }

  &.icon-trash {
    cursor: pointer;

    .a {
      fill: none;
      stroke: #141414;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
  }

  &.icon-certificate {
    .a {
      fill: none;
      stroke: #919499;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.8px;
    }
  }

  &.icon-group {
    .a {
      fill: none;
      stroke: #919499;
      stroke-width: 1.8px;
    }
  }

  &.icon-noun-chapter {
    .a {
      fill: #919499;
    }
  }

  &.icon-noun-time {
    .a {
      fill: #919499;
    }
  }

  &.icon-video-gallery {
    .a {
      fill: #919499;
    }
  }

  &.icon-quote-start {
    .a {
      opacity: 0.05;
    }
    .b {
      fill: #3478f6;
    }
  }

  &.icon-quote-end {
    .a {
      opacity: 0.05;
    }
    .b {
      fill: #3478f6;
    }
  }

  &.icon-unfinished-circle {
    .a {
      fill: #e2e3e6;
      stroke: #fff;
    }
    .b {
      fill: #fff;
    }
    .c {
      stroke: none;
    }
    .d {
      fill: none;
    }
  }

  &.icon-video-back {
    .a {
      fill: none;
      stroke: #fefefe;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
  }

  &.icon-video-next {
    .a {
      fill: none;
      stroke: #fefefe;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
  }

  &.seconds-back {
    .a {
      fill: none;
      stroke: #fefefe;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
    .b {
      fill: #fefefe;
      font-size: 22px;
      font-family: SFProText-Light, SF Pro Text;
      font-weight: 300;
      letter-spacing: -0.012em;
    }
  }

  &.seconds-next {
    .a {
      fill: none;
      stroke: #fefefe;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
    .b {
      fill: #fefefe;
      font-size: 22px;
      font-family: SFProText-Light, SF Pro Text;
      font-weight: 300;
      letter-spacing: -0.012em;
    }
  }

  &.small-play {
    .a {
      fill: #fff;
      stroke: #fefefe;
      stroke-linejoin: round;
    }
  }

  &.icon-pause {
    .a {
      fill: #fefefe;
    }
  }

  &.icon-breadcrumb-separator {
    .a {
      fill: #141414;
    }
    .a,
    .b {
      stroke: #141414;
      stroke-linecap: round;
    }
    .b {
      fill: none;
    }
  }

  &.icon-first-footer {
    .fil3 {
      fill: none;
    }
    .fil0 {
      fill: #fefefe;
    }
    .fil2 {
      fill: #ffa80b;
      fill-opacity: 0.078431;
    }
    .fil4 {
      fill: url(#id1);
    }
    .fil1 {
      fill: url(#id2);
      fill-opacity: 0.258824;
    }
  }

  &.icon-sound {
    min-width: 28px;

    .a {
      fill: #fff;
    }
  }

  &.icon-video-settings {
    .a {
      fill: none;
      stroke: #fefefe;
      stroke-linecap: square;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
  }

  &.icon-full-size {
    .a {
      fill: #fefefe;
    }
  }

  &.icon-subtitles {
    .a {
      fill: #fefefe;
    }
  }

  &.icon-no-sound {
    .a {
      fill: #fff;
    }
  }

  &.icon-minimize {
    .a {
      fill: #fefefe;
    }
  }

  &.icon-no-subtitles {
    .a {
      fill: #fefefe;
    }
    .b,
    .d {
      fill: none;
    }
    .b {
      stroke: #fff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.8px;
    }
    .c {
      stroke: none;
    }
  }

  &.icon-copy-icon {
    .a {
      fill: #f5f5f5;
    }
    .b {
      fill: #141414;
    }
  }

  &.icon-burger-menu {
    .a {
      fill: none;
      stroke: #fff;
      stroke-linecap: round;
      stroke-width: 3px;
    }
  }

  &.icon-user-icon {
    .a {
      fill: #fff;
    }
    .b {
      fill: none;
    }
    .c {
      clip-path: url(#a);
    }
    .d,
    .e {
      stroke: none;
    }
    .e {
      fill: #fff;
    }
  }

  &.icon-row-back {
    .a {
      fill: none;
      stroke: #141414;
      stroke-linecap: round;
      stroke-width: 1.8px;
    }
  }

  &.icon-has-claps {
    .a {
      fill: #141414;
    }
  }

  &.icon-shield {
    .a {
      fill: #fff;
    }
    .a,
    .b {
      stroke: #ae005a;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 0.5px;
    }
    .b {
      fill: none;
    }
    .c {
      fill: #ae005a;
    }
  }

  &.icon-notify-check {
    .a {
      fill: #25bd30;
      stroke: #25bd30;
      fill-rule: evenodd;
    }
  }

  &.icon-notify-alert {
    .a {
      fill: #f43243;
    }
  }

  &.icon-notify-close {
    .a {
      fill: none;
      stroke: #fff;
      stroke-linecap: round;
      stroke-width: 2px;
    }
  }

  &.icon-no-notifications {
    .a {
      fill: #fff;
    }
    .b {
      fill: #d7eef7;
    }
    .c {
      fill: rgba(0, 0, 0, 0);
      stroke: #141414;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 3px;
    }
  }

  &.icon-no-bookmarks {
    .a {
      fill: #fff;
    }
    .b {
      fill: #d7eef7;
    }
    .c {
      fill: rgba(0, 0, 0, 0);
      stroke: #141414;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 3px;
    }
  }

  &.icon-switch-devices {
    .a {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .a,
    .b {
      stroke: #141414;
      stroke-width: 3px;
    }
    .b {
      fill: rgba(255, 255, 255, 0);
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }
  }

  &.icon-switch-devices-placeholder {
    .a {
      fill: #d7eef7;
    }
    .b {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .b,
    .c {
      stroke: #141414;
      stroke-width: 3px;
    }
    .c {
      fill: rgba(255, 255, 255, 0);
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }
  }

  &.icon-active-device-play {
    .a {
      fill: #3478f6;
      opacity: 0.8;
    }
    .b {
      fill: #fff;
    }
  }

  &.icon-pc {
    .a {
      fill: none;
      stroke: #454546;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.5px;
    }
  }

  &.icon-table {
    .a,
    .b {
      fill: none;
      stroke-linecap: square;
      stroke-miterlimit: 10;
      stroke-width: 1.5px;
    }
    .a {
      stroke: #444;
    }
    .b {
      stroke: #454546;
    }
  }

  &.icon-phone {
    .a {
      fill: none;
      stroke: #454546;
      stroke-linecap: square;
      stroke-miterlimit: 10;
      stroke-width: 1.5px;
    }
  }
}
