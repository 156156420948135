.header-tooltip {
  position: relative;
  display: block;

  &__body {
    position: absolute;
    top: 45px;
    right: 0;
    background-color: $white;
    border-radius: rem(16px);
    box-shadow: 0 4px 24px $dark-10;
    padding: rem(10px 24px);
    z-index: 99;
  }
}