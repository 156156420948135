@import './Item';
@import './Banner';
@import './Filters';
@import './Search';
@import './Webinars';

@include local-mq($until: lg) {
  .col {
    padding: 0;
  }
}