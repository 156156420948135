.sample-audio {
  border-bottom: 1px solid $border-color;
  padding-bottom: rem(20px);
  margin-top: rem(24px);

  &__button {
    width: 100%;
    border: none;
    border-radius: 8px;

    &:hover {
      border: none;

      .ebs-button {
        background-color: $blue-main;
      }

      .sample-audio__button__play {
        background-color: $white;

        svg {
          fill: $blue-main;
        }
      }
    }

    .ebs-button {
      display: flex;
      gap: rem(10px);
      justify-content: center;
      align-items: center;
      background-color: $bg-blue-40;
      width: 100%;
      border-radius: 8px;
      height: rem(50px);
      font-size: rem(16px);
      color: $blue-main;
    }

    &__play {
      background-color: $blue-main;
      border-radius: 100%;
      width: rem(20px);
      height: rem(20px);
      position: relative;

      svg {
        fill: $white;
        width: 8px;
        height: 8px;
        position: absolute;
        top: rem(6px);
        left: rem(6px);
      }
    }
  }
}