.program-card {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 16px;
  box-shadow: 0 4px 10px $dark-10;

  &__image-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: rem(220px);

    &:hover {
      .program-card__image-play {
        display: block;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 16px 16px 0 0;
  }

  &__image-play {
    display: none;
    color: $white;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    background-color: $blue-main;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 14px;

    svg {
      width: rem(24px);
      height: rem(24px);
    }
  }

  &__body {
    height: rem(200px);
    border-radius: 16px;
    margin-top: rem(-20px);
    background: $white;
    position: relative;
    padding: rem(10px 16px 16px 16px);

    &__header {
      min-height: rem(90px);
    }

    &__title {
      font-size: rem(18px);
      font-weight: 600;
      line-height: rem(23px);
      color: $dark-blue;
      @include text-ellipses(2);

      &__event-custom {
        margin-top: rem(10px);
        height: rem(46px);
        display: flex;
        align-items: center;
      }
    }

    &__description {
      margin-top: rem(8px);
      font-size: rem(14px);
      line-height: rem(18px);
      color: $dark-blue;
      @include text-ellipses(2);
    }

    &__statistic {
      width: calc(100% - 32px);
      margin-top: rem(24px);
      position: absolute;
      bottom: rem(10px);
    }

    &__statistic-members {
      align-items: flex-end;
    }

    &__text-statistic {
      font-size: rem(12px);
    }

    &__action-button {
      font-size: rem(14px);
      line-height: rem(14px);
      @include switzer-medium;
    }

    &__statistic-text {
      font-size: rem(12px);
      font-weight: 400;
      line-height: rem(16px);
      color: $text-color;
    }

    &__action-button a {
      color: $blue-main;
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(18px);
    }

    &__progress-bar {
      //margin-top: rem(20px);
    }

    &__chapter {
      font-weight: 400;
      font-size: rem(12px);
      line-height: rem(15px);
      color: $dark-blue;
      @include text-ellipses(1);

      .bold {
        font-weight: 600;

        &::first-letter {
          text-transform: capitalize;
        }
      }

      &__clap-clap {
        width: rem(13px);
        height: rem(13px);
        position: relative;
        top: 3px;
        left: 2px;
      }
    }

    &__chapter-description {
      margin-top: rem(4px);
      font-weight: 400;
      font-size: rem(12px);
      line-height: rem(15px);
      color: $text-color;
      @include text-ellipses(2);
    }

    &__footer {
      width: calc(100% - 32px);
      margin-top: rem(24px);
      position: absolute;
      bottom: rem(16px);

      &__get-free a {
        color: $blue-main;
        font-weight: 700;
        font-size: rem(14px);
        text-align: right;
      }
    }

    &__contain {
      font-size: rem(14px);
      line-height: rem(15px);
      font-weight: 400;
      margin-top: rem(7px);
    }

    &__new {
      background-color: #68D391;
      color: $white;
      padding: rem(2px 10px);
      border-radius: 4px;
      font-weight: 500;
      font-size: rem(14px);
      text-align: center;
      display: inline-block;
      margin-top: rem(10px);
    }
  }

  &__user-icon {
    width: rem(18px);
    height: rem(18px);
    border-radius: 100%;
    background-color: #e6eaec;

    svg {
      width: rem(18px);
      height: rem(18px);
      color: $dark-50;
    }
  }

  &__timeline {
    margin-top: rem(25px);

    &__time {
      color: $text-color;
      font-size: rem(14px);
      line-height: rem(18px);
      margin-bottom: rem(10px);
    }

    &__live {
      color: $orange;
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(18px);
    }

    &__send {
      color: $dark-blue;
      width: rem(24px);
      height: rem(24px);
      cursor: pointer;
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .program-card {
    &__body {
      height: rem(220px);

      .program-card__body__title__event-custom {

        .program-card__body__title {
          @include text-ellipses(3);
        }


      }
    }
  }
}