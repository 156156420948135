@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-from-up {
  from {
    transform: translate(50%, -100%);
  }
  to {
    transform: translate(50%, 0);
  }
}

.m-notify {
  @include flex-center-column;
  box-sizing: border-box;
  position: fixed;
  overflow: hidden auto;
  z-index: 9999;
  max-height: 100%;
  width: rem($notify-width);
  padding: rem($gutter-size);
  top: rem(97px);
  right: 50%;
  transform: translate(50%, 0);
  animation: toast-from-up .5s;

  @include local-mq($until: sm) {
    width: 100%;
  }

  > .ebs-space__item {
    width: 100%;
  }

  .ebs-icon {
    color: $notify-color;
  }

  &--top-right {
    top: 0;
    right: 0;
  }

  &--top-right &__item {
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }

  &--bottom-right {
    bottom: 0;
    right: 0;
  }

  &--bottom-right &__item {
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }

  &--top-left {
    top: 0;
    left: 0;
  }

  &--top-left &__item {
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }

  &--bottom-left {
    bottom: 0;
    left: 0;
  }

  &--bottom-left &__item {
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }

  &__item {
    position: relative;
    color: $notify-color;
    padding: rem($notify-padding + 2);
    border-radius: rem(10px);
    box-shadow: $notify-box-shadow;
    background-color: #202020;

    @include local-mq($until: sm) {
      justify-content: space-between;
    }

    .m-icon {
      font-size: rem(20px);
    }

    &-close {
      margin-left: rem(100px);

      @include local-mq($until: sm) {
        margin-left: 0;
      }

      .ebs-button {
        display: flex;
        align-items: center;
        padding: 0;
      }
    }

    &--regular {
      background-color: $white;
      color: $text-color;

      .ebs-icon {
        color: $primary-color;
      }
    }

    &--primary {
      background-color: $primary-color;
    }

    &--success {
      background-color: $success-color;
    }

    &--danger {
      background-color: $danger-color;

      .ebs-icon {
        path:last-child {
          fill: $danger-color;
        }
      }
    }

    &--info {
      background-color: $info-color;
    }

    &--warning {
      background-color: $warning-color;
    }

    &-message {
      font-size: rem(20px);
    }
  }
}
