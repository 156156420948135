.back-bread-crumbs {
  display: flex;
  align-items: center;
  gap: rem(14px);
  margin-top: rem(30px);

  &__button {
    color: $dark-blue;
    width: rem(36px);
    height: rem(36px);
    padding: rem(6px);
    background-color: $bg-blue-40;
    border-radius: 100%;
    cursor: pointer;
  }

  &__text {
    color: $dark-blue;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(18px);
  }
}

@media (max-width: $grid-sm-size + 36) {
  .back-bread-crumbs {
    margin-top: 0;
    position: relative;
    width: 100%;
    height: rem(44px);

    &__button {
      display: none;
    }

    &__text {
      position: absolute;
      top: 0;
      color: $blue-main;
      line-height: rem(44px);
      padding-left: rem(44px);
      border-bottom: 1px solid $blue-border-color;
      width: calc(100% + 44px);
      margin-left: rem(-22px);
      margin-right: rem(-22px);

      &:before {
        content: ' ';
        width: rem(10px);
        height: rem(10px);
        display: block;
        position: absolute;
        top: rem(16px);
        left: rem(22px);
        border-bottom: 1.5px solid $blue-main;
        border-left: 1.5px solid $blue-main;
        border-bottom-left-radius: 2px;
        transform: rotate(45deg);
      }
    }
  }
}