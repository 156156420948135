.languages {
  &__item {
    padding: rem(20px 4px);
    cursor: pointer;
    border-bottom: 1px solid $blue-border-color;
    color: $dark-blue;
    position: relative;
    white-space: nowrap;

    &:last-child {
      border: none;
    }

    &.active {
      color: $blue-main;
    }

    &:hover {
      background-color: $grey-light;

      &:after {
        content: ' ';
        width: rem(24px);
        height: 100%;
        background-color: $grey-light;
        position: absolute;
        right: rem(-24px);
        top: 0;
      }

      &:before {
        content: ' ';
        width: rem(24px);
        height: 100%;
        background-color: $grey-light;
        position: absolute;
        left: rem(-24px);
        top: 0;
      }
    }
  }
}

.languages-flag {
  width: rem(38px);
  height: rem(38px);
  border-radius: 50px;
  border: rem(7px) solid $bg-blue-40;
  cursor: pointer;

  svg {
    width: rem(26px);
    height: rem(26px);
    border-radius: 50%;
  }
}

.languages-button {
  border: 1px solid $white;
  font-size: rem(16px);
  @include switzer-semibold;
  line-height: rem(42px);
  border-radius: 8px;
  display: inline-block;
  padding: rem(0 15px 0 45px);
  position: relative;

  svg {
    width: rem(20px);
    height: rem(20px);
    position: absolute;
    left: rem(15px);
    top: calc(50% - 9px);
  }
}

.footerTooltip {
  width: fit-content;
  cursor: pointer;
}

.footerTooltip .header-tooltip__body {
    top: rem(-270px);
    left: 0;
    width: fit-content;
}