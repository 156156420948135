.m-counter {
  position: relative;
  cursor: pointer;

  .m-counter-number {
    display: none;
    border-radius: 50%;
    background-color: $blue-main;
    color: #fff;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: rem(-6px);
    z-index: 2;
    font-size: rem(18px);

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &.active {
      width: rem(40px);
      height: rem(40px);
      display: flex;
      animation-name: claps-appearing;
      animation-duration: 0.4s;
      top: rem(-45px);
    }

    &.hidden {
      animation: claps-hide;
      animation-duration: 0.5s;
    }

    @keyframes claps-appearing {
      from {
        opacity: 0;
        top: 0;
      }
      to {
        opacity: 1;
        top: rem(-45px);
      }
    }

    @keyframes claps-hide {
      0% {
        opacity: 1;
        top: rem(-45px);
      }
      100% {
        opacity: 0;
        top: rem(-70px);
      }
    }
  }

  &.add {
    z-index: 2;
    animation-name: claps-add-number;
    animation-duration: 0.2s;

    .m-icon {
      animation: claps-add-hand;
      animation-duration: 0.2s;
    }
  }

  @keyframes claps-add-hand {
    0% {
      transform: scale(0.5);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes claps-add-number {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}
