.collapse-program-card {
  margin-bottom: rem(16px);
  box-shadow: 0 4px 24px $dark-10;
  border-radius: 16px;

  &__body {
    display: flex;
    justify-content: space-between;
    gap: rem(20px);
    height: rem(148px);
  }

  &__image {
    border-radius: 16px 0 0 16px;

    img {
      border-radius: 16px 0 0 16px;
      width: rem(285px);
      height: rem(148px);
      object-fit: cover;
    }
  }

  &__image-mobile {
    display: none;
  }

  &__header {
    width: 100%;
    padding: rem(25px 25px 25px 0);
  }

  &__header-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__name {
    font-size: rem(14px);
  }

  &__control-button {
    svg {
      cursor: pointer;
      width: rem(22px);
      height: rem(22px);

      &.active {
        color: $blue-main;
      }
    }
  }

  &__chapter-title {
    color: $dark-blue;
    font-size: rem(18px);
    @include switzer-semibold;
    margin-top: rem(16px);
    margin-bottom: rem(6px);
    @include text-ellipses(1);
  }

  &__chapter-description {
    font-size: rem(14px);
    color: $dark-blue;
    line-height: rem(18px);
    @include text-ellipses(2);
  }


  &__episodes {
    padding: rem(20px);
    display: flex;
    flex-direction: column;
    gap: rem(16px);

    &__item {
      display: flex;
      gap: rem(20px);
      justify-content: space-between;
      border-radius: 16px;
      border: 1px solid $bg-blue-40;

      &:hover {
        .collapse-program-card__episodes__item__image-play {
          display: block;
        }
      }

      &__image {
        border-radius: 16px 0 0 16px;
        position: relative;
        width: rem(163px);
        height: rem(150px);

        img {
          width: rem(163px);
          height: rem(150px);
          object-fit: cover;
          border-radius: 16px 0 0 16px;
        }
      }

      &__image-play {
        cursor: pointer;
        display: none;
        color: $white;
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        background-color: $blue-main;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        padding: 7px 10px;

        svg {
          width: rem(13px);
          height: rem(13px);
        }
      }

      &__body {
        width: 100%;
        padding: rem(26px 26px 26px 0);

        &__title {
          font-size: rem(18px);
          line-height: rem(24px);
          @include switzer-semibold;
          color: $dark-blue;
          display: flex;
          justify-content: space-between;

          span:nth-child(1) {
            min-width: rem(20px);
            padding-right: rem(3px);
            text-align: right;
          }

          span:nth-child(2) {
            width: 100%;
            @include text-ellipses(1);
          }
        }

        &__title-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;

          svg {
            width: rem(20px);
            height: rem(20px);
            cursor: pointer;
          }
        }

        &__description {
          color: $dark-blue;
          font-size: rem(14px);
          line-height: rem(18px);
          @include text-ellipses(2);
          margin-top: rem(6px);
          margin-bottom: rem(16px);
          width: 95%;
        }

        &__continue {
          font-size: rem(12px);
          @include switzer-regular;
        }
      }
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .collapse-program-card {
    margin-left: rem(-22px);
    margin-right: rem(-22px);
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid $blue-border-color;
    padding: rem(20px 22px);

    &:last-child {
      border-bottom: none;
    }


    &__image {
      display: none;
    }

    &__name {
      font-size: rem(14px);
      line-height: rem(18px);
    }

    &__chapter-title {
      font-size: rem(14px);
      @include switzer-semibold;
      line-height: rem(18px);
      margin-top: 0;
    }

    &__image-mobile {
      display: block;
      width: rem(80px);
      height: rem(80px);
      border-radius: 16px;

      &__wrapper {
        display: flex;
        justify-content: flex-start;
        gap: rem(18px);
        margin-top: rem(18px);
      }

      img {
        width: rem(80px);
        height: rem(80px);
        border-radius: 16px;
        object-fit: cover;
      }
    }

    &__chapter-description {
      @include text-ellipses(5);
      font-size: rem(14px);
      line-height: rem(18px);
    }

    &__control-button {
      svg {
        height: rem(20px);
        width: rem(20px);
      }
    }

    &__header {
      padding: 0;
    }

    &__episodes {
      padding: rem(20px 10px);
      gap: rem(20px);

      &__item {
        border: none;

        &__image {
          display: none;
        }

        &__body {
          padding: 0;

          &__title {
            font-size: rem(14px);
            line-height: rem(18px);
            @include switzer-semibold;

            span:nth-child(1) {
              min-width: rem(25px);
              padding-right: rem(3px);
              text-align: right;
            }

            span:nth-child(2) {
              width: 100%;
              word-break: break-word;
              @include text-ellipses(2);
            }
          }

          &__continue {
            font-size: rem(12px);
            margin-left: rem(27px);
          }

          &__description {
            display: none !important;
          }
        }
      }
    }
  }
}