$font-size-base: 14.968px;

//$font-family: 'Product Sans Light Regular';


html,
body {
  @media (max-width: 1023.99px) {
    font-size: 13.5px !important;
  }

  @media (max-width: 1259.99px) {
    font-size: 13px !important;
  }
}

// Main colors
$primary-color: $blue-main;

$primary-600: $blue-50;
$primary-700: $blue-50;
