.carousel {
  position: relative;
}

.carousel-container {
  padding-bottom: rem(35px);
  width: calc(100% + 20px);
  transform: translate(-10px);

  &.show-carousel-options {
    width: calc(100% + 10px);

    @include local-mq($until: small-tablet) {
      width: calc(100% + 32px);
    }
  }

  > ul {
    margin-left: 10px;

    .carousel-item {
      padding: 0 10px;

      &:last-child {
        @include local-mq($until: small-tablet) {
          padding-right: 24px;
        }
      }

      &:first-child {
        @include local-mq($until: small-tablet) {
          padding: 0 10px;
        }
      }
    }
  }
}

.carousel-buttons {
  position: absolute;
  top: rem(-58px);
  right: 0;
  display: flex;
  gap: rem(10px);

  &__left,
  &__right {
    width: rem(36px);
    height: rem(36px);
    background-color: $bg-blue-40;
    border-radius: 50%;
    padding: rem(6px);
    cursor: pointer;

    svg {
      width: rem(24px);
      height: rem(24px);
      color: $dark-blue;
    }

    &:hover {
      box-shadow: 0 0 7px $dark-10;
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.disabled:hover {
      box-shadow: none;
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .carousel-buttons {
    display: none;
  }
}
