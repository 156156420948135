.chapter-block {
  display: flex;
  border-radius: 16px;
  box-shadow: 0 4px 24px $dark-10;
  height: rem(148px);
  background-color: $white;
  margin-bottom: rem(20px);

  &__image {
    img {
      width: rem(285px);
      height: 100%;
      object-fit: cover;
      border-radius: 16px 0 0 16px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: rem(25px 45px 25px 20px);
    width: 100%;
  }

  &__wrapper {
    width: 90%;
  }

  &__number {
    font-size: rem(14px);
    margin-bottom: rem(16px);
  }

  &__program-title {
    display: none;
  }

  &__title {
    font-size: rem(18px);
    color: $dark-blue;
    @include switzer-semibold;
    margin-bottom: rem(6px);
    @include text-ellipses(2);
  }

  &__description {
    line-height: rem(18px);
    font-size: rem(14px);
    color: $dark-blue;
    @include text-ellipses(2);
  }


  &__button {
    width: rem(36px);
    height: rem(36px);
    margin: auto 0;

    svg {
      color: $dark-blue;
      width: rem(36px);
      height: rem(36px);
      padding: rem(6px);
      background-color: $bg-blue-40;
      border-radius: 100%;
      cursor: pointer;
    }
  }
}


@media (max-width: $grid-sm-size + 36) {
  .chapter-block {
    box-shadow: none;
    height: rem(72px);
    gap: rem(14px);
    margin-bottom: rem(24px);

    &__image {
      img {
        width: rem(72px);
        height: rem(72px);
        border-radius: 16px;
      }
    }

    &__body {
      padding: 0;
    }

    &__wrapper {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
    }

    &__description {
      display: none !important;
    }

    &__button {
      display: none;
    }

    &__number {
      @include text-ellipses(1);
    }

    &__number, &__title {
      margin-bottom: 0;
    }

    &__program-title, &__number {
      display: inline;
      font-size: rem(12px);
    }

    &__program-title {
      margin-left: rem(10px);
    }
  }
}