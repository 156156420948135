.qa-banner {
  @include flex;
  background-color: #f3f6fa;
  padding-top: rem(73px);
  margin-top: rem(70px);
  padding-left: rem(55px);
  padding-right: rem(55px);

  @include mq($from: small-desktop) {
    max-height: rem(285px);
    overflow: hidden;
  }

  .ebs-button__wrapper {
    width: rem(260px);
    height: rem(50px);
    border-radius: rem(8px);

    @include mq($from: small-desktop) {
      margin-bottom: rem(59px);
    }
    
    .ebs-button {
      @include flex-center;
      font-size: rem(20px);
      font-weight: 500;
    }
  }

  .head-title {
    margin: 0;
    font-size: rem(30px);
    max-width: rem(398px);
    font-weight: 600;
  }

  .ebs-space__item {
    @include flex;
  }

  @include mq($until: small-desktop) {
    margin-top: rem(36px);
    padding-top: rem(48px);

    > .ebs-space {
      flex-direction: column-reverse;
      justify-content: center;

      > .ebs-space__item {
        margin-right: 0 !important;

        &:first-child {
          margin-top: rem(32px);
        }

        .ebs-space {
          text-align: center;
          align-items: center;
        }
      }
    }

    .head-title {
      font-size: rem(24px);
      max-width: rem(264px);
    }

    .ebs-button__wrapper {
      width: rem(264px);

      .ebs-button {
        font-size: rem(18px);
      }
    }
  }
}
