.author {
  margin-top: rem(60px);
  box-shadow: 0 4px 24px $dark-10;
  border-radius: 16px;
  padding: rem(16px);
  position: relative;

  &__label {
    display: none;
  }

  &__avatar {
    position: absolute;
    top: rem(-32px);
    right: rem(16px);
    background-color: transparent;
    width: rem(133px);
    height: rem(133px);
    filter: drop-shadow(0px 4px 24px rgba(5, 41, 65, 0.1));
    box-shadow: none;
  }

  &__avatar-data {
    &__full-name {
      @include switzer-semibold;
      font-size: rem(18px);
      line-height: rem(24px);
      color: $dark-blue;
    }

    &__position {
      font-size: rem(12px);
      line-height: rem(15px);
    }

    &__description {
      margin-top: rem(16px);
      font-size: rem(14px);
      line-height: rem(18px);
      color: $dark-blue;
    }

    &__fake-author {
      width: rem(135px);
      height: rem(35px);
      float: right;
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .author {
    &__label {
      display: block;
      color: $dark-blue;
      @include switzer-semibold;
      font-size: rem(18px);
      line-height: rem(24px);
      position: absolute;
      top: rem(-32px);
    }

    &__avatar {
      width: rem(100px);
      height: rem(100px);
      top: rem(-38px);
    }

    &__avatar-data {
      &__fullname {
        font-size: rem(16px);
      }

      &__description {
        display: none;
      }
    }
  }
}