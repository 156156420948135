.attending {
  display: flex;
  flex-direction: row-reverse;
  margin-right: rem(8px);

  &__img {
    width: rem(18px);
    height: rem(18px);
    border-radius: 100%;
    margin-left: rem(-9px);
    background-position: center;
    background-size: contain;
  }

  &__icon {
    width: rem(18px);
    height: rem(18px);
    border-radius: 100%;
    background-color: #e6eaec;
    margin-left: rem(-9px);

    svg {
      width: rem(18px);
      height: rem(18px);
      color: $dark-50;
    }
  }

  & > div:last-child {
    margin-left: 0;
  }
}