@mixin local-mq(
  $from: false,
  $until: false,
  $and: false,
  $media-type: $mq-media-type,
  $breakpoints: $mq-breakpoints,
  $responsive: $mq-responsive,
  $static-breakpoint: $mq-static-breakpoint
) {
  $min-width: 0;
  $max-width: 0;
  $media-query: ''; // From: this breakpoint (inclusive)
  @if $from {
    @if type-of($from) ==number {
      $min-width: $from;
    } @else {
      $min-width: mq-get-breakpoint-width($from, $breakpoints);
    }
  }
  // Until: that breakpoint (exclusive)
  @if $until {
    @if type-of($until) ==number {
      $max-width: $until;
    } @else {
      $max-width: mq-get-breakpoint-width($until, $breakpoints) - 0.01px;
    }
  }
  // Responsive support is disabled, rasterize the output outside @media blocks
  // The browser will rely on the cascade itself.
  @if $responsive==false {
    $static-breakpoint-width: mq-get-breakpoint-width($static-breakpoint, $breakpoints);
    $target-width: $static-breakpoint-width; // Output only rules that start at or span our target width
    @if ($and==false and $min-width <=$target-width and ($until==false or $max-width >=$target-width)) {
      @content;
    }
  }
    // Responsive support is enabled, output rules inside @media queries
  @else {
    @if $min-width !=0 {
      $media-query: '#{$media-query} and (min-width: #{$min-width})';
    }
    @if $max-width !=0 {
      $media-query: '#{$media-query} and (max-width: #{$max-width})';
    }
    @if $and {
      $media-query: '#{$media-query} and #{$and}';
    }
    // Remove unnecessary media query prefix 'all and '
    @if ($media-type== 'all' and $media-query != '') {
      $media-type: '';
      $media-query: str-slice(unquote($media-query), 6);
    }
    @media #{$media-type + $media-query} {
      @content;
    }
  }
}

@mixin hidden-scroll {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
  }
}

@mixin parents-gradient { //todo check if is needed
  background-image: -moz-linear-gradient(120deg, $blue-main 0%, $red 50%, $orange 100%);
  background-image: -webkit-linear-gradient(120deg, $blue-main 0%, $red 50%, $orange 100%);
  background-image: -ms-linear-gradient(120deg, $blue-main 0%, $red 50%, $orange 100%);
  background-image: linear-gradient(120deg, $blue-main 0%, $red 50%, $orange 100%);
}

@mixin parents-gradient-reverse {
  background-image: -moz-linear-gradient(120deg, $orange 0%, $red 50%, $blue-main 100%);
  background-image: -webkit-linear-gradient(120deg, $orange 0%, $red 50%, $blue-main 100%);
  background-image: -ms-linear-gradient(120deg, $orange 0%, $red 50%, $blue-main 100%);
  background-image: linear-gradient(120deg, $orange 0%, $red 50%, $blue-main 100%);
}

@mixin text-ellipses($line-clamp: 1) {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@mixin -webkit-autofill() {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}