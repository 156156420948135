.qa-filters {
  &__card {
    @extend .qa-item;
    width: rem(285px);
    max-height: rem(478px);
    // overflow-y: auto;

    .head-title {
      font-size: rem($gutter-size);
      margin-top: 0;
      margin-bottom: 0;
    }

    .ebs-button {
      font-weight: 400;
      font-size: rem($gutter-size);
    }

    .ebs-card__body > .row:not(:last-child) {
      padding-bottom: rem($gutter-size);
    }

    .ebs-card__body {
      display: flex;
      flex-direction: column;
    }

    &-clear .ebs-button {
      padding-left: 0;
      padding-right: 0;
    }

    &-overflow {
      overflow-y: auto;
      height: 100%;

      &.is-scroll {
        box-shadow: inset -3px 0px 5px rgba(5, 41, 65, 0.1);
      }

      &::-webkit-scrollbar {
        width: rem(5px);
      }
    }
  }
}

.qa-filters__mobile {
  display: none;
  position: fixed;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  transform: scale(0);
  transition: all 0.3s;

  .title {
    font-weight: 600;
    font-size: rem(18px);
  }

  .ebs-checkbox__wrapper {
    @include flex-center-vert;
    position: relative;
    height: rem(50px);
    border-bottom: 1px solid #dfe5f6;

    .ebs-checkbox {
      top: 50%;
      margin: 0;
      transform: translate3d(0, -50%, 0);
      width: rem(20px);
      height: rem(20px);
      color: $dark-blue;

      &__text {
        color: $dark-blue;
        padding-left: rem(32px);
      }

      &:before {
        display: none;
      }
    }
  }

  .ebs-button--primary {
    height: rem(50px);
  }

  .row {
    height: calc(100%);

    .ebs-space__item {
      width: 100%;
    }
  }

  &.visible {
    transform: scale(1);
  }

  &-clear .ebs-button {
    padding-left: rem($gutter-size);
    padding-right: rem($gutter-size);
  }

  &-header {
    height: rem(69px);
    transition: all 300ms;

    .ebs-button__wrapper {
      padding: rem($gutter-size);

      .ebs-button {
        padding: 0;
      }
    }

    &.is-scroll {
      box-shadow: 0px 4px 24px rgba(5, 41, 65, 0.1);
    }
  }

  &-overflow {
    overflow-y: auto;
    height: calc(100vh - #{rem(69px)} - #{rem(50px)} - #{var(--bs-gutter-y)} - 30px - 48px);
    padding-left: rem($gutter-size) !important;
    padding-right: rem($gutter-size) !important;

    &::-webkit-scrollbar {
      width: rem(5px);
    }
  }

  &-btn {
    margin-left: rem($gutter-size);
    margin-right: rem($gutter-size);
    width: calc(100% - #{$base-size});
  }
}

@include local-mq($until: lg) {
  .qa-filters {
    display: none;
  }

  .qa-filters__mobile {
    @include flex;
  }
}
