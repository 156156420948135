.user-tooltip {

  &__item {
    padding: rem(20px 4px);
    cursor: pointer;
    border-bottom: 1px solid $blue-border-color;
    color: $dark-blue;
    display: block;
    position: relative;

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: $grey-light;

      &:after {
        content: ' ';
        width: rem(24px);
        height: 100%;
        background-color: $grey-light;
        position: absolute;
        right: rem(-24px);
        top: 0;
      }

      &:before {
        content: ' ';
        width: rem(24px);
        height: 100%;
        background-color: $grey-light;
        position: absolute;
        left: rem(-24px);
        top: 0;
      }
    }

  }

  &__avatar {
    min-width: rem(200px);
    margin-bottom: rem(30px);

    .ebs-avatar--small {
      width: rem(48px);
      height: rem(48px);
    }

    .ebs-avatar__inline-alt {
      color: $dark-blue;
      font-size: rem(16px);
    }
  }
}