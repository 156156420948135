@keyframes growProgressBar {
  0%, 33% { --pgPercentage: 0; }
  100% { --pgPercentage: var(--value); }
}

@property --pgPercentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

.chapter {
  position: relative;
  cursor: pointer;
  padding: rem(10px 14px);

  &__progress {
    --size: 1.143rem;
    --fg: #2660F6;
    --bg: #99A0B480;
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: 
      radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0)
      ;
    font-family: Helvetica, Arial, sans-serif;
    font-size: calc(var(--size) / 5);
    color: var(--fg);
    margin-left: rem(10px);

    &-text {
      margin-left: rem(7px);
      color: $blue-main;
    }
  }

  &.open &__body {
    margin-bottom: rem(4px);
  }

  &__extra-title {
    @include flex-center-vert;
    font-size: rem(14px);
    line-height: rem(18px);
    margin-bottom: rem(4px);
  }

  &__title {
    font-size: rem(14px);
    line-height: rem(18px);
    @include switzer-semibold;
    color: $dark-blue;
    margin-bottom: rem(14px);
  }

  &__control-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: rem(10px 14px);
  }

  &__body {
    margin-bottom: rem(28px);

    .ebs-space__item:last-child {
      width: 100%;
    }
  }

  &__avatar {
    width: rem(80px);
    height: rem(80px);
    object-fit: cover;
    border-radius: 16px;
  }

  &__description {
    color: $dark-blue;
    font-size: rem(14px);
    line-height: rem(18px);
  }

  &__extended-episodes {
  }
}
