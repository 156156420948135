.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.5s;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.25);

  &.ant-switch-checked {
    background-color: #1890ff;

    .ant-switch-handle {
      left: calc(100% - 20px);
    }

    .ant-switch-inner {
      margin: 0 25px 0 7px;
    }
  }

  .ant-switch-handle {
    top: 2px;
    width: 18px;
    height: 18px;
    position: absolute;
    transition: all 1s;

    &:before {
      position: absolute;
      transition: all 1s;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      border-radius: 9px;
      box-shadow: 0 2px 4px 0 rgba(0,35,11,.2);
      content: "";
    }
  }

  .ant-switch-inner {
    display: block;
    margin: 0 7px 0 25px;
    color: #fff;
    font-size: 12px;
    transition: all 1s;
  }
}
