@font-face {
  font-family: 'Switzer Light';
  src: url('../fonts/switzer/Switzer-Light.ttf') format('opentype');
}
@font-face {
  font-family: 'Switzer Regular';
  src: url('../fonts/switzer/Switzer-Regular.ttf') format('opentype');
}
@font-face {
  font-family: 'Switzer Medium';
  src: url('../fonts/switzer/Switzer-Medium.ttf') format('opentype');
}
@font-face {
  font-family: 'Switzer Semibold';
  src: url('../fonts/switzer/Switzer-Semibold.ttf') format('opentype');
}
@font-face {
  font-family: 'Switzer Bold';
  src: url('../fonts/switzer/Switzer-Bold.ttf') format('opentype');
}

@mixin switzer-light {
  font-family: 'Switzer Light', sans-serif;
}
@mixin switzer-regular {
  font-family: 'Switzer Regular', sans-serif;
}
@mixin switzer-medium {
  font-family: 'Switzer Medium', sans-serif;
}
@mixin switzer-semibold {
  font-family: 'Switzer Semibold', sans-serif;
}
@mixin switzer-bold {
  font-family: 'Switzer Bold', sans-serif;
}
