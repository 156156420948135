.reviews-tab {
  margin-bottom: rem(50px);

  &__title {
    font-size: rem(18px);
    line-height: rem(24px);
    margin-bottom: rem(12px);
  }

  &__rating-body-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__add-rating {
    padding-bottom: rem(38px);
    display: flex;
    justify-content: center;

    .ebs-button__wrapper {
      padding: rem(0 100px);
      height: rem(50px);
      line-height: rem(50px);
    }
  }

  &__rating-body {
    border-radius: 16px;
    box-shadow: 0 4px 24px $dark-10;

    &__details {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: rem(20px);
      padding: rem(40px 20px);
    }

    &__stars {
      display: flex;
      gap: rem(7px);
      font-size: rem(16px);

      svg {
        color: $orange;
        fill: $orange;
        width: rem(22px);
        height: rem(22px);
      }
    }

    &__detailed {
      display: flex;
      width: 100%;
      gap: rem(24px);
    }

    &__progress-bar {
      width: 100%;
      display: block;

      .ant-progress {
        margin-bottom: 0;

        .ant-progress-outer {
          .ant-progress-inner,
          .ant-progress-inner .ant-progress-bg {
            height: rem(8px);
          }
        }
      }

    }

    &__rating {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__rating-stars {
      justify-content: center;
      gap: rem(12px);
      margin-top: rem(20px);

      svg {
        color: $orange;
        fill: $orange;
        width: rem(24px);
        height: rem(24px);
      }
    }

    &__rating-big {
      @include switzer-medium;
      font-size: rem(98px);
      line-height: rem(98px);
      text-align: center;
      color: $blue-main;
    }

    &__rating-text {
      text-align: center;
      font-size: rem(14px);
      width: 100%;
      display: block;
      margin-top: rem(16px);
    }
  }

  &__add-review {
    padding: rem(40px 20px);

    &__star {
      svg {
        color: $orange;
        fill: $white;
        cursor: pointer;

        &.filled {
          fill: $orange;
        }
      }
    }

    .error {
      color: $red;
      font-size: rem(12px);
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .reviews-tab {
    margin-bottom: 0;

    &__add-rating {
      margin-top: rem(30px);

      .ebs-button__wrapper {
        width: 100%;
      }
    }

    &__rating-body {
      box-shadow: none;

      &__details {
        padding: 0;
      }

      &__rating-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: rem(16px);
      }

      &__rating-big {
        font-size: rem(30px);
        line-height: rem(30px);
      }

      &__rating-stars {
        justify-content: flex-start;
        margin-top: 0;
      }

      &__rating-text {
        text-align: left;
        margin-top: rem(17px);
      }

      &__rating {
        margin-bottom: rem(45px);
      }

      &__stars {
        align-items: baseline;

        svg {
          width: rem(12px);
          height: rem(12px);
        }
      }

      &__progress-bar {
        .ant-progress {
          line-height: 0;

          .ant-progress-outer .ant-progress-inner .ant-progress-bg,
          .ant-progress-outer .ant-progress-inner {
            height: rem(4px);
          }
        }
      }
    }

    &__rating-body-wrapper {
      flex-direction: column-reverse;
    }
  }
}