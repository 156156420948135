.help-page {
  margin-top: rem(70px);
  margin-bottom: rem(70px);
  width: rem(590px);
  box-shadow: 0 4px 70px rgba(5, 41, 65, 0.07);
  border-radius: 20px;

  &__form {
    padding: rem(40px);
  }

  &__head-title {
    margin: rem(0 0 40px);
    justify-content: center;
  }

  &__form-field {
    .ebs-form__field__label {
      font-family: 'Switzer Light', sans-serif;
      font-size: rem(16px);
      font-weight: 400;
      line-height: rem(28px);
    }

    @include -webkit-autofill();

    .ebs-input {
      border: none;
      border-radius: 10px;
      height: rem(50px);
    }

    .ebs-input__wrapper {
      border-radius: 10px;
      border: 1px solid $blue-border-color;
      background-color: $white;
    }

    .ebs-textarea {
      border-radius: 10px;

      &::placeholder {
        font-size: rem(16px);
        @include switzer-light;
        color: $text-color;
      }
    }

    &__extra {
      a {
        color: $blue-main;
        font-size: rem(14px);
        font-weight: 600;
        font-family: 'Switzer Regular', sans-serif;
      }

      & + .ebs-form__field__required {
        display: none;
      }
    }

    &.ebs-form__item:not(:last-child) {
      margin-bottom: rem(24px);
    }
  }

  .drop-zone__title {
    width: rem(300px);
    font-size: rem(14px);
    @include switzer-medium;
    color: $quiz-sub-title;
    line-height: rem(26px);
  }

  &__form-footer {
    padding: rem(0 6px);
    margin-top: rem(40px);

    .ebs-button__wrapper {
      width: 100%;
      height: rem(50px);
      background-color: $blue-main;
      border-radius: 8px;

      button {
        font-size: rem(20px);
        font-weight: 500;
        height: rem(50px);
      }
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .help-page {
    width: 94%;
    margin: rem(54px 0 60px);
    border-radius: 15px;

    &__form-field {
      .ebs-textarea {
        min-height: rem(120px);
      }
    }

    .head-title {
      margin-bottom: rem(20px);
    }

    &__form {
      padding: rem(30px 20px 20px 20px);
    }

    &__form-footer {
      padding: 0;
    }
  }
}