$grey-light: #f5f5f5;
$white: #fff;
$white80: rgba(255, 255, 255, 0.8);
$dark-blue: #052941;
$blue-main: #2660F6;
$blue-30: rgba(38, 96, 246, 0.3);

$green: #03A63D;

$purple: #500089;
$orange: #F29F02;
$turquoise: #06D1C2;
$red: #F2461D;
$dark-50: rgba(5, 41, 65, 0.5);
$dark-10: rgba(5, 41, 65, 0.1);
$dark-15: rgba(5, 41, 65, 0.15);

$bg-blue-40: rgba(223, 225, 232, 0.4);
$bg-warm-40: rgba(234, 226, 205, 0.4);
$blue-border-color: #DFE5F6;
$background-content: $white;

$blue-50: #c3d7fd;
$blue-100: #e1ebfe;

$yellow-50: #ffca28;

$quiz-sub-title: #5C5F80;

$text-color: #99A0B4;
$text-color-secondary: #bfbfbf;
$text-color-grey-5: #f0f0f0;
$text-color-grey-10: #e2e3e6;
$text-color-grey-25: #d9d9d9;
$text-color-grey-50: #bfbfbf;
$text-color-grey-55: #919499;
$text-color-grey-60: #707070;
$text-color-grey-75: #5e5e5e;
$text-color-grey-100: #454546;
$text-color-grey-300: #323232;
$text-color-grey-600: #141414;