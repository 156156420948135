.header {
  background-color: $white;
  box-shadow: 0 rem(8px) rem(30px) rgba(0, 0, 0, 0.05);
  height: rem(90px);

  &.fixed {
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  &__logo {
    width: rem(115px);
    height: rem(28px);
    color: $blue-main;
    margin: rem(30px 0);
  }

  &__switch-device {
    color: $dark-blue;
    width: rem(32px);
    height: rem(32px);
    stroke-width: 4;
    cursor: pointer;
  }

  &__search-form.ebs-input__search-wrapper .ebs-input__search {
    background-color: $bg-blue-40;
    border-radius: 60px;
    width: rem(290px);
    height: rem(38px);

    svg.ebs-icon--search {
      color: $text-color;
    }

    .ebs-input__container {
      height: rem(38px);
    }

    input.ebs-input {
      color: $text-color;
      height: rem(38px);
      line-height: rem(38px);
      font-size: rem(17px);
      font-weight: 200;
      cursor: text;
      @include placeholder {
        font-size: rem(17px);
        color: $text-color;
      }
    }
  }

  &__login {
    color: $blue-main;
    cursor: pointer;

    svg {
      color: $white;
      stroke: $dark-blue;
    }
  }

  &__notification {
    width: rem(32px);
    height: rem(32px);
    color: $dark-blue;
  }

  &__search-component {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $white;
    top: 0;
    left: 0;
    z-index: 9;

    .header__search-form {
      .ebs-input__search-wrapper .ebs-input__search {
        background-color: $white;
        height: rem(70px);
        border: none;
        border-bottom: 1px solid $blue-border-color;
        border-radius: 0;
        width: 100%;

        .ebs-input__container {
          height: rem(70px);
        }

        .ebs-input__clear {

        }
      }

      &__close {
        position: absolute;
        right: rem(17px);
        top: rem(27px);
        background: $text-color;
        color: $white;
        width: rem(16px);
        height: rem(16px);
        display: block;
        border-radius: 50%;
        text-align: center;
        padding: 0;
      }
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .header {
    height: rem(70px);

    &__logo {
      height: rem(34px);
      margin: rem(18px 0);

      path:not(:first-child) {
        display: none;
      }
    }

    &__login svg {
      width: rem(20px);
      height: rem(20px);
    }

    &__search svg {
      width: rem(22px);
      height: rem(22px);
      color: $dark-blue;
    }

    &__mobile {
      display: flex;
      gap: rem(30px);
    }

    &__search,
    &__login {
      display: flex;
      align-items: center;
    }
  }
}