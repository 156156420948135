.chapters {
  &__program-title {
    font-size: rem(18px);
    line-height: rem(24px);
    margin-bottom: rem(16px);
  }

  &__progress-bar {
    color: $dark-blue;
    font-size: rem(12px);
    position: relative;

    &__cup-icon {
      color: $blue-main;
      width: rem(24px);
      height: rem(24px);
      position: absolute;
      right: 0;
    }

    span {
      @include switzer-semibold;
    }

    .ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
      height: 6px;
    }
  }

  &__wrapper {
    margin-top: rem(40px);
    margin-bottom: rem(70px);
    box-shadow: 0 4px 24px $dark-10;
    border-radius: 16px;
    padding: rem(10px);

    .zh-scroll {
      padding: 0;
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .chapters {
    &__program-title,
    &__progress-bar {
      display: none;
    }

    &__wrapper {
      box-shadow: none;
      padding: 0;
    }
  }
}