.reviews {
  margin-bottom: rem(70px);

  &__title {
    font-size: rem(18px);
    line-height: rem(24px);
    margin-bottom: rem(20px);
  }


  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(36px);

    &__avatar {
      display: flex;
      gap: rem(14px);

      .ebs-avatar {
        width: 36px;
        height: 36px;
      }
    }

    &__user-name {
      font-size: rem(14px);
      line-height: rem(16px);
      color: $dark-blue;
    }

    &__description {
      margin: rem(16px 0);
      color: $dark-blue;
    }

    &__created-at {
      color: #737373;
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .reviews {
    &__title {
      display: none;
    }
  }
}