@import 'fonts';
@import 'variables';
@import 'palette';
@import 'mixin';
@import 'helpers';

// react-ebs-ui config
@import 'config';

@import '~ebs-design/dist/styles/index.scss';

// ui
@import 'ui/atoms/styles', 'ui/molecules/styles', 'ui/organisms/styles', 'features/styles';

@import 'react-multi-carousel/lib/styles.css';

@import 'video.js/dist/video-js.css';

html,
body {
  @include switzer-regular;
  font-size: 14.968px;

  @include local-mq($until: extra-wide) {
    font-size: 14px !important;
  }

  @include local-mq($until: small-tablet) {
    font-size: 15px !important;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.d-flex {
  display: flex;
}

.m-bold {
  color: $text-color-grey-600;
}

.m-hide {
  display: none;
}

// todo put to register page scss
.m-signin-label {
  @include switzer-medium;
  font-size: rem(24px);
  color: $text-color-grey-600;

  @include local-mq($until: xxl) {
    font-size: rem(18px);
  }
}

.m-signin-link {
  @include switzer-medium;
  font-size: rem(24px);
  color: $blue-main;
  margin-left: rem(10px);

  > a {
    color: $blue-main;
  }

  @include local-mq($until: xxl) {
    font-size: rem(18px);
  }
}

.h-100 {
  height: 100%;
}

.ebs-notify {
  position: fixed;
}

.ebs-input__wrapper--active,
.ebs-input__wrapper.focus {
  border-color: $blue-main;
}

.ebs-select__input-wrapper.active .ebs-select__input-dropdown-wrapper .ebs-select__input {
  border-color: $blue-main;
}

.ebs-input__phone.active .form-control,
.ebs-input__phone.active .flag-dropdown {
  border-color: $blue-main;
}

.ebs-form__item {
  .row > * {
    padding-left: $gutter-size / 3;
    padding-right: $gutter-size / 3;

    @include local-mq($until: xl) {
      padding: 0;
    }
  }
}

.ebs-extra--text {
  color: $blue-main;
  border-color: $blue-main;
}

.ebs-form__field__label {
  font-size: rem(16px);
}

.ebs-radio__group .ebs-radio__input:checked ~ .ebs-radio .ebs-radio__dot {
  border-color: $blue-main;
  background-color: $blue-main;
}

.ebs-select__dropdown-item:hover .ebs-select__dropdown-item-text {
  color: $blue-main;
}

.ebs-label {
  white-space: normal;
}

.ebs-label--circle,
.ebs-label--fill,
.ebs-label--ghost {
  height: auto;
}

.ebs-tooltip__wrapper {
  border-radius: 10px;
}

.ebs-action__tooltip-icon {
  transform: rotate(90deg);
}

.ebs-action__tooltip-item {
  font-size: rem(20px);
  @include switzer-regular;
  color: $text-color-grey-100;

  &:hover {
    background-color: $blue-50;
    color: $text-color-grey-100;
  }
}

.ebs-action__tooltip-title {
  font-size: rem(16px);
  @include switzer-bold;
}

.css-1eg0sfo-skeletonStyles-Skeleton {
  border-radius: 0 !important;
  line-height: inherit !important;
}

@keyframes hold-cover-appearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-page-col {
  flex: 0 0 auto;
  width: 80%;

  @include local-mq($until: extra-wide) {
    width: 90%;
  }

  @include local-mq($until: xl) {
    width: 91%;
  }
}

.watch-layout-col {
  flex: 0 0 auto;
  width: 91%;
}

// todo after section
.m-align-right {
  text-align: right;

  .m-bottom-logo {
    height: rem(36px);
    max-width: 100%;

    @include local-mq($until: sm) {
      max-height: 30px;
    }
  }
}

// todo payment switch
.m-space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .ebs-radio {
    margin-top: rem(7px);
  }
}

// todo home page
.m-containter {
  @include parents-gradient;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 80vh;
    display: block;
    max-width: 80%;
  }

  .text-white {
    color: $white;
  }
}

// todo sign in page
.main-layout {
  min-height: 100vh;
  width: 100%;

  .on-hold-cover {
    width: 100%;
    height: calc(100vh - 62px);
    background-color: #14141480;
    position: fixed;
    z-index: 9;
    top: rem(80px);
    animation-name: hold-cover-appearance;
    animation-duration: 1s;
    overflow: hidden;

    @include local-mq($until: xxl) {
      top: rem(78px);
    }

    @include local-mq($until: xl) {
      top: rem(93px);
    }

    @include local-mq($until: lg) {
      top: rem(93px);
    }

    @include local-mq($until: sm) {
      top: rem(60px);
      height: calc(100vh - 40px);
    }

    .m-active-device {
      height: rem(65px);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: $blue-main;

      @include local-mq($until: xxl) {
        height: rem(85px);
      }

      .m-device-name {
        margin-right: rem(70px);
        font-size: rem(20px);
        color: #fff;

        @include local-mq($until: xl) {
          font-size: rem(25px);
        }

        @include local-mq($until: sm) {
          font-size: rem(20px);
          height: rem(30px);
        }

        .icon-active-device-play {
          margin-top: rem(7px);
        }

        .bold {
          @include switzer-bold;
          margin-left: rem(5px);
        }
      }
    }
  }

  .m-section-bg {
    background-color: #fff;
    padding: rem(30px);
  }

  .mt-100 {
    margin-top: rem(100px);
  }
}

// todo stripe form
.m-input-holder {
  position: relative;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 0.28571rem;

  .m-input {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    background: transparent;
    border: none;
    width: 100%;
    display: block;
    font-size: 17px;
    padding: 0.42857rem 0.57143rem;
    line-height: 1.414;
    color: #121634;
    height: rem(36px);
  }
}

.m-input-label {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  position: relative;
  white-space: nowrap;
  font-size: rem(16px);
  color: $text-color-grey-600;
  border-radius: 4px;
}

.m-add-new {
  cursor: pointer;

  .m-add-new-right {
    color: $primary-color;
  }
}

.m-price-wrapper {
  margin-top: rem(60px);
  color: $text-color-grey-600;

  .m-price {
    @include switzer-bold;
    font-size: rem(28px);

    margin: 0 rem(10px) 0 rem(10px);

    @include local-mq($until: sm) {
      margin: 0 rem(3px) 0 rem(3px);
      font-size: rem(24px);
    }
  }
}

// todo physical products form
.product-form {
  .m-section .m-section-content {
    margin-top: 30px;
  }
}

//todo unsubscribe page
.unsubscribe-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  > h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 100px;
  }
}

// todo section atom
.m-section {
  .m-section-name {
    @include switzer-light;
    font-size: 28px;
    color: $text-color-grey-100;
    letter-spacing: -1px;

    @include local-mq($until: xxl) {
      margin: rem(16px) rem(16px) 0;
    }
  }

  .m-section-content {
    margin-top: 60px;

    @include local-mq($until: xxl) {
      margin-top: 30px;
    }
  }

  .m-bar {
    height: rem(2px);
    background-image: -moz-linear-gradient(120deg, $orange 0%, $red 50%, $blue-main 100%);
    background-image: -webkit-linear-gradient(120deg, $orange 0%, $red 50%, $blue-main 100%);
    background-image: -ms-linear-gradient(120deg, $orange 0%, $red 50%, $blue-main 100%);
    background-image: linear-gradient(120deg, $orange 0%, $red 50%, $blue-main 100%);
  }

  .m-size-big {
    input {
      height: rem(42px);
    }
  }

  .m-reset-password a {
    @include switzer-light;
    font-size: rem(18px);
    line-height: rem(24px);
    color: $text-color-grey-60;
  }

  .m-submit-row {
    text-align: center;
    margin-top: rem(60px);
    margin-bottom: rem(30px);

    .m-submit {
      cursor: pointer;
      color: #1890ff;

      button {
        @include switzer-regular;
        font-size: rem(20px);
        padding: rem(7px) rem(50px);
        border-radius: rem(7px);
      }
    }
  }

  .m-subsection {
    @include switzer-regular;
    font-size: rem(20px);
    color: $text-color-grey-600;
  }
}

.m-login-wrapper {
  background-color: $white;
  padding: rem(30px);
  margin-top: rem(60px);

  @include local-mq($until: sm) {
    padding: 0;
    margin-top: 0;
  }
}

.with-action {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.saved-episodes {
  margin: rem(31px) 0 rem(17px);
  justify-content: center;

  @include local-mq($from: qhd) {
    margin-top: rem(21px);
  }

  @include local-mq($until: md) {
    margin-top: rem(35px);
  }

  @include local-mq($until: sm) {
    display: none;
  }

  .saved-episodes__col {
    max-width: $max-qhd;
    justify-content: flex-end;
    display: flex;
  }

  span {
    @include local-mq($from: qhd) {
      font-size: rem(18px);
    }

    @include local-mq($until: xl) {
      font-size: rem(25px);
    }
  }
}

.m-section-title {
  @include switzer-bold;
  color: $text-color-grey-600;
  font-size: rem(40px);
  max-width: $max-qhd;

  @include local-mq($from: qhd) {
    font-size: rem(33px);
  }

  @include local-mq($until: xl) {
    font-size: rem(37px);
  }

  @include local-mq($until: sm) {
    font-size: rem(20px);
  }
}

.m-btn-with-event {
  font-size: rem(24px);
  color: $blue-main;
  cursor: pointer;
}

// responsive
.xxl-no-padding {
  @include local-mq($until: xxl) {
    padding: 0;
  }
}

.sm-no-padding {
  @include local-mq($until: sm) {
    padding: 0;
  }
}

.sm-padding-10 {
  @include local-mq($until: sm) {
    padding: 0 10px;
  }
}

.sm-pl-20 {
  @include local-mq($until: sm) {
    padding-left: rem(20px);
  }
}

.sm-no-margin {
  @include local-mq($until: sm) {
    margin: 0;
  }
}

.xl-hide {
  @include local-mq($until: xl) {
    display: none;
  }
}

._2k-100 {
  @include local-mq($from: qhd) {
    width: 100%;
  }
}

.sm-no-padding-right {
  @include local-mq($until: sm) {
    padding-right: 0;
  }
}

.xxl-ml-5 {
  @include local-mq($until: xxl) {
    margin-left: 5px;
  }
}

.xxl-mr-5 {
  @include local-mq($until: xxl) {
    margin-right: 5px;
  }
}

.col-extra-wide-9 {
  @include local-mq($from: extra-wide) {
    flex: 0 0 auto;
    width: 66.6%;
    max-width: 1300px;
  }
}

.px-50 {
  margin-left: rem(50px);
  margin-right: rem(50px);
}

.bg-white {
  background-color: #fff;
}

.qr-code {
  position: absolute;
  width: 100%;

  &__logo {
    width: rem(300px);
    top: rem(20px);
    margin-top: rem(20px);
    margin-bottom: rem(20px);
  }

  &__wrapper {
    width: rem(600px);
    margin: 0 auto;
    text-align: center;
  }

  &__title {
    font-family: "SF Pro", sans-serif;
    font-size: rem(24px);
    font-weight: 300;
    color: #36545f;
    display: block;
    margin-bottom: rem(30px);
  }

  &__subtitle {
    font-family: "SF Pro", sans-serif;
    font-size: rem(18px);
    font-weight: 600;
    margin-bottom: rem(30px);
    display: block;
    color: #000;
  }

  img {
    border: 3px rgba(0, 0, 0, 0.15) solid;
    border-radius: 20px;
    margin-bottom: rem(63px);
  }
}

// todo chapter
.m-chapter {
  cursor: pointer;
  padding: 0 rem(10px) rem(20px);
  background-color: #fff;

  @include local-mq($until: extra-wide) {
    padding: 0 rem(20px) rem(20px);
  }

  @include local-mq($until: xl) {
    padding: 0 0 rem(10px);
  }

  .m-chapter-extra-title {
    font-size: rem(16px);
    color: $text-color-grey-55;
    line-height: rem(14px);
    margin-top: 15px;

    @include local-mq($until: extra-wide) {
      font-size: rem(13px);
    }

    @include local-mq($until: xl) {
      font-size: rem(16px);
    }
  }

  .m-chapter-title {
    @include switzer-medium;
    font-size: rem(20px);
    color: $text-color-grey-600;

    @include local-mq($until: extra-wide) {
      font-size: rem(17px);
    }

    @include local-mq($until: xl) {
      font-size: rem(20px);
    }

    @include local-mq($until: sm) {
      font-size: rem(16px);
    }
  }

  .m-chapter-description {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: rem(100px);
    overflow: hidden;

    @include local-mq($until: extra-wide) {
      font-size: rem(17px);
      height: rem(70px);
      -webkit-line-clamp: 3;
    }

    @include local-mq($until: xl) {
      -webkit-line-clamp: 4;
    }

    @include local-mq($until: sm) {
      font-size: rem(14px);
      height: rem(80px);
    }
  }

  .m-chapter-photo {
    &.ebs-avatar--small {
      height: 100px;
      width: 100px;

      @include local-mq($until: extra-wide) {
        height: 75px;
        width: 75px;
      }
    }
  }

  .m-divider {
    margin-top: 15px;
    border: 1px solid $text-color-grey-25;
  }

  .m-chapter-action {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    font-size: rem(20px);

    @include local-mq($until: sm) {
      width: 15px;
    }

    &.open {
      transform: rotate(180deg);
    }
  }
}

// todo chapters
.m-chapter-wrapper {
  .m-chapters-container {
    margin-top: rem(35px);

    @include local-mq($until: xl) {
      margin: 0;
    }
  }
}

.carousel-btn-group {
  position: relative;
  top: -50%;

  .m-arrow {
    position: absolute;
    cursor: pointer;
    transform: translate(0, -30%);
    font-size: rem(45px);

    @include local-mq($from: qhd) {
      font-size: rem(38px);
    }

    &.left {
      left: rem(-70px);

      @include local-mq($from: qhd) {
        left: rem(-120px);
      }

      @include local-mq($until: extra-wide) {
        left: rem(-60px);
      }

      @include local-mq($until: lg) {
        left: rem(-40px);
      }

      @include local-mq($until: sm) {
        display: none;
      }
    }

    &.right {
      right: rem(-68px);

      @include local-mq($from: qhd) {
        right: rem(-118px);
      }

      @include local-mq($until: extra-wide) {
        right: rem(-58px);
      }

      @include local-mq($until: lg) {
        right: rem(-38px);
      }

      @include local-mq($until: sm) {
        display: none;
      }
    }
  }
}

.image-cover {
  object-fit: cover;
  object-position: top;
}

// todo my program card
.m-programs-wrapper {
  max-width: $max-qhd;

  .my-program-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
    margin: rem(30px) 0;

    @include local-mq($until: sm) {
      flex-direction: column;
    }

    &:first-child {
      margin: 0;

      @include local-mq($until: sm) {
        margin: rem(30px) 0;
      }
    }

    .program-image-container {
      display: flex;
      justify-content: center;
      align-content: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      position: relative;
      max-height: 384px;
      width: 33.3333333333%;

      @include local-mq($until: extra-wide) {
        width: 40%;
      }

      @include local-mq($until: xl) {
        width: 39%;
      }

      @include local-mq($until: md) {
        width: 40%;
      }

      @include local-mq($until: sm) {
        width: 100%;
        min-height: 215px;
      }

      &:hover {
        .video-hover { //todo refactor/delete this
          opacity: 1;
        }
      }

      .program-image-holder {
        height: 100%;
        width: 100%;

        @include local-mq($until: sm) {
          height: auto;
          width: auto;
        }

        img {
          height: 100%;
          width: 100%;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          cursor: pointer;

          @include local-mq($until: sm) {
            border-bottom-left-radius: 0;
            border-top-right-radius: 10px;
          }
        }

        .video-hover { //todo refactor/delete this
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: 0.3s ease;
          position: absolute;
          cursor: pointer;
          font-size: rem(70px);

          @include local-mq($until: sm) {
            opacity: 1;
          }
        }
      }
    }

    .program-info-container {
      flex: 0 0 auto;
      width: 60%;

      @include local-mq($until: extra-wide) {
        width: 65%;
      }

      @include local-mq($until: xl) {
        width: 61%;
      }

      @include local-mq($until: md) {
        width: 60%;
      }

      @include local-mq($until: sm) {
        width: 100%;
      }

      .program-info {
        padding: rem(32px) rem(26px) rem(26px) rem(42px);

        @include local-mq($from: qhd) {
          padding: rem(23px) rem(23px) rem(23px) rem(30px);
        }

        @include local-mq($until: extra-wide) {
          padding: rem(19px) rem(26px) rem(25px) rem(28px);
        }

        @include local-mq($until: lg) {
          padding: 16px;
        }

        @include local-mq($until: md) {
          height: auto;
        }

        .program-title {
          @include switzer-medium;
          font-size: rem(40px);
          color: $text-color-grey-600;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;

          @include local-mq($from: qhd) {
            font-size: rem(33px);
          }

          @include local-mq($until: extra-wide) {
            line-height: rem(54px);
          }

          @include local-mq($until: xl) {
            font-size: rem(38px);
            line-height: rem(48px);
          }

          @include local-mq($until: md) {
            font-size: rem(30px);
            line-height: rem(39px);
          }

          @include local-mq($until: sm) {
            font-size: rem(20px);
          }
        }

        .program-author {
          color: $text-color-grey-55;
          font-size: rem(20px);
          height: rem(28px);
          display: inline-block;

          @include local-mq($from: qhd) {
            font-size: rem(15px);
          }

          @include local-mq($until: extra-wide) {
            font-size: rem(20px);
            line-height: rem(14px);
          }

          @include local-mq($until: xl) {
            font-size: rem(23px);
          }

          @include local-mq($until: md) {
            font-size: rem(21px);
          }

          @include local-mq($until: sm) {
            display: none;
          }
        }

        .program-statistic {
          margin: rem(26px) 0 rem(56px) 0;
          font-size: rem(20px);
          color: $text-color-grey-55;
          fill: $text-color-grey-55;

          @include local-mq($from: qhd) {
            font-size: rem(15px);
            margin: rem(16px) 0 rem(36px) 0;
          }

          @include local-mq($until: extra-wide) {
            font-size: rem(21px);
            margin: rem(15px) 0 rem(15px) 0;
          }

          @include local-mq($until: xl) {
            font-size: rem(20px);
            margin: rem(32px) 0 rem(5px) 0;
          }

          @include local-mq($until: md) {
            font-size: rem(21px);
            margin: rem(18px) 0 rem(5px) 0;
          }

          .m-text-statistic {
            @include switzer-regular;
            margin-left: rem(10px);

            @include local-mq($until: sm) {
              font-size: rem(13px);
            }
          }
        }

        .m-progress-bar-row {
          @include local-mq($until: extra-wide) {
            margin-bottom: rem(42px);
          }

          @include local-mq($until: xl) {
            margin: 0;
          }

          @include local-mq($until: md) {
            margin: 0;
          }

          @include local-mq($until: sm) {
            margin: rem(35px) 0;
          }
        }

        .m-progress-message {
          color: $text-color-grey-55;

          @include local-mq($from: qhd) {
            font-size: rem(12px);
            margin-top: rem(3px);
          }

          @include local-mq($until: extra-wide) {
            font-size: rem(17px);
          }

          @include local-mq($until: xl) {
            font-size: rem(15px);
          }
        }

        .last-watched-episode {
          height: auto;
          display: flex;
          align-items: flex-end;

          .m-continue-watch-btn {
            display: flex;
            justify-content: flex-end;
            padding: 0;

            @include local-mq($until: sm) {
              display: none;
            }
          }
        }

        .m-episode {
          &__chapter {
            @include switzer-regular;
            color: $text-color-grey-55;
            font-size: rem(18px);

            @include local-mq($until: extra-wide) {
              font-size: rem(17px);
            }

            @include local-mq($until: xl) {
              font-size: rem(15px);
            }

            @include local-mq($until: md) {
              font-size: rem(18px);
            }
          }
        }

        .m-episode-description {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          color: $text-color-grey-600;
          min-height: rem(50px);
          font-size: rem(16px);

          @include local-mq($from: qhd) {
            min-height: rem(41px);
          }

          @include local-mq($until: extra-wide) {
            font-size: rem(15px);
            margin-top: rem(13px);
            min-height: rem(32px);
          }

          @include local-mq($until: xl) {
            font-size: rem(15px);
            -webkit-line-clamp: 3;
            margin: 0;
          }

          @include local-mq($until: md) {
            font-size: rem(18px);
            -webkit-line-clamp: 2;
          }
        }

        .m-continue-watch {
          border-radius: rem(10px);
          border-width: 2px;
          padding: 0 15px;
          margin-right: rem(5px);

          > button {
            font-size: rem(24px);
            font-weight: 500;
            padding: rem(7px);

            @include local-mq($from: qhd) {
              font-size: rem(19px);
              padding: rem(5px) rem(5px) rem(7px);
            }

            @include local-mq($until: extra-wide) {
              border-radius: 7px;
            }

            @include local-mq($until: xl) {
              font-size: rem(19px);
              font-weight: 600;
            }
          }
        }
      }
    }

    .ebs-icon {
      font-size: rem(18px);
    }
  }
}

// todo comments
.m-comment {
  margin-top: rem(30px);

  &:first-child {
    margin-top: 0;
  }

  .m-reply {
    margin-top: rem(30px);
  }

  .m-comment-photo {
    display: flex;
    justify-content: flex-end;
    padding: 0;

    @include local-mq($until: sm) {
      padding: 0;
      margin-left: 0;
    }

    .m-comment-photo-container {
      position: relative;
      height: max-content;

      .ebs-avatar--small {
        width: 80px;
        height: 80px;

        @include local-mq($until: extra-wide) {
          width: rem(61px);
          height: rem(61px);
        }

        @include local-mq($until: xl) {
          width: rem(70px);
          height: rem(70px);
        }

        @include local-mq($until: sm) {
          width: 50px;
          height: 50px;
        }
      }

      &.is-admin {
        .ebs-avatar--small {
          border: 1px solid $blue-main;
        }

        .icon-shield {
          bottom: rem(-5px);
          position: absolute;
          right: 0;
          font-size: rem(30px);
          z-index: 1;

          @include local-mq($until: extra-wide) {
            font-size: rem(22px);
          }
        }
      }
    }
  }

  .m-comment-content {
    padding: 0 0 0 rem(14px);

    .m-comment-author {
      @include switzer-medium;
      font-size: rem(28px);
      color: $text-color-grey-600;
      line-height: rem(34px);

      @include local-mq($until: extra-wide) {
        font-size: rem(23px);
      }

      @include local-mq($until: sm) {
        font-size: rem(20px);
      }
    }

    .m-expert-label {
      font-size: rem(20px);
      color: $text-color-grey-55;

      @include local-mq($until: extra-wide) {
        font-size: rem(16px);
      }

      @include local-mq($until: lg) {
        font-size: rem(15px);
      }
    }

    .m-comment-text {
      display: table;
      word-wrap: break-word;
      white-space: pre-line;
      font-size: rem(20px);
      color: $text-color-grey-600;
      margin: rem(15px) 0 rem(20px);
      line-height: rem(26px);

      @include local-mq($until: extra-wide) {
        font-size: rem(17px);
      }

      @include local-mq($until: sm) {
        font-size: rem(16px);
      }
    }

    .m-comment-actions {
      font-size: rem(20px);
      color: $text-color-grey-55;

      @include local-mq($until: extra-wide) {
        font-size: rem(18px);
      }

      @include local-mq($until: sm) {
        font-size: rem(16px);
      }

      .m-reply-toggle {
        @include local-mq($until: sm) {
          margin-left: rem(30px);
        }
      }
    }
  }
}

// todo episodes
.m-episodes-wrapper {
  background-color: #fff;

  .m-episode {
    padding: 15px 0 15px 0;
    margin-right: rem(9px);

    @include local-mq($until: extra-wide) {
      padding: rem(15px) rem(6px);
    }

    .icon-checked {
      margin-top: rem(2px);
      font-size: rem(20px);

      @include local-mq($until: extra-wide) {
        font-size: rem(17px);
      }
    }

    .m-bookmark-container {
      font-size: rem(28px);
      padding: 0;
      display: flex;
      justify-content: center;

      @include local-mq($until: extra-wide) {
        font-size: rem(24px);
      }

      @include local-mq($until: sm) {
        font-size: rem(22px);
      }

      .icon-bookmark {
        margin-right: 10px;

        > path {
          stroke: $text-color-grey-55;
        }
      }

      .icon-filled-bookmark {
        margin-right: 10px;
      }
    }

    .m-black-text {
      color: $text-color-grey-600;

      @include local-mq($until: extra-wide) {
        font-size: rem(13px);
      }

      @include local-mq($until: sm) {
        min-height: rem(20px);
      }
    }

    .m-episode-time {
      margin-top: rem(3px);

      @include local-mq($until: extra-wide) {
        font-size: rem(12px);
      }

      @include local-mq($until: sm) {
        margin: 0;
      }
    }

    &.active {
      background-color: $blue-30;
    }
  }
}

// todo resources
.m-task-text {
  font-size: rem(24px);
  color: $text-color-grey-600;

  @include local-mq($until: sm) {
    font-size: rem(16px);
  }
}

// todo settings layout
.m-settings-layout {
  position: relative;
  top: rem(61px);
  left: 0;
  width: 100%;
  //background-color: $grey-light;
  z-index: 3;

  .m-settings-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: rem(43px);
    margin-bottom: 0;

    @include local-mq($until: sm) {
      display: block;
      margin-top: 0;
      margin-bottom: rem(20px);
      width: rem(605px);
    }

    .m-settings {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: rem(280px);
      position: fixed;

      @include local-mq($until: sm) {
        position: relative;
        width: 100%;
      }

      .m-settings-separator {
        border: 1px solid $text-color-grey-55;
        margin-left: rem(10px);
      }

      .m-settings-title {
        @include switzer-semibold;
        font-size: rem(24px);
        color: $text-color-grey-600;
        padding-left: rem(10px);
        margin: rem(3px 0);
      }

      .m-setting {
        font-size: rem(24px);
        color: $text-color-grey-600;
        padding-left: rem(10px);
        margin: rem(3px 0);
        cursor: pointer;

        &.selected {
          background-color: $blue-50;
        }
      }
    }
  }
}

// todo settings card
.m-card-box {
  border: 1px solid $text-color-grey-60;
  border-radius: 0;
  max-width: rem(801px);

  @include local-mq($until: lg) {
    max-width: rem(705px);
  }
  @include local-mq($until: md) {
    max-width: rem(605px);
  }

  .m-card-header {
    padding-top: rem(35px);
    border-bottom: 1px solid $text-color-grey-60;

    .m-card-title {
      @include switzer-semibold;
      font-size: rem(24px);
      color: $text-color-grey-600;
    }
  }

  .m-card-body {
    padding: rem(25px);
  }
}

.no-shadow {
  box-shadow: none;
}

.no-border {
  border: 0;
}

.m-pre-footer-gradient {
  height: 150px;
  background-image: -moz-linear-gradient(180deg, #fff 0%, #f5f5f550 50%, #f5f5f5 100%);
  background-image: -webkit-linear-gradient(180deg, #fff 0%, #f5f5f550 50%, #f5f5f5 100%);
  background-image: -ms-linear-gradient(180deg, #fff 0%, #f5f5f550 50%, #f5f5f5 100%);
  background-image: linear-gradient(180deg, #fff 0%, #f5f5f550 50%, #f5f5f5 100%);

  @include local-mq($until: sm) {
    height: 100px;
  }
}

.m-grey-separator {
  background-color: #fff;
  padding-top: rem(100px);

  > div {
    height: 8px;
    background-color: $grey-light;

    @include local-mq($until: xxl) {
      margin-right: 0;
    }
  }
}

.m-video-settings {
  width: 250px;
  text-wrap: none;
}

// todo sidenav
.m-side-nav {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.8);
  animation: side-nav-background-appearing;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  z-index: 11;
  justify-content: flex-end;

  @keyframes side-nav-background-appearing {
    from {
      background-color: transparent;
    }
    to {
      background-color: rgba(20, 20, 20, 0.8);
    }
  }

  .m-side-nav-content {
    background-color: #fff;
    animation: side-nav-appearing;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    position: fixed;
    height: 100%;

    @keyframes side-nav-appearing {
      from {
        right: -33.3333333333%;
      }
      to {
        right: 0;
      }
    }

    .m-side-nav-close {
      font-size: rem(24px);
      display: flex;
      justify-content: flex-end;
      padding-right: 52px;

      @include local-mq($until: sm) {
        font-size: rem(16px);
      }
    }

    .m-side-bar-divider {
      border: 1px solid $text-color-grey-10;
      margin: 30px 0 30px 0;

      &:first-child {
        border: 1px solid $text-color-grey-50;
      }
    }
  }
}

// todo bookmarks
.m-bookmarks {
  .m-bookmarks-title {
    @include switzer-semibold;
    font-size: rem(24px);
    color: $text-color-grey-600;
    padding-left: 10px;
    margin-bottom: 30px;
  }

  .m-bookmarks-wrapper {
    max-height: 100vh;
    overflow-y: auto;
    padding: 0;

    .m-bookmark {
      padding-left: 10px;

      .m-bookmark-title {
        @include switzer-semibold;
        font-size: rem(18px);
        color: $text-color-grey-600;
      }

      .m-bookmark-description {
        font-size: rem(16px);
        color: $text-color-grey-55;
      }

      .m-bookmark-remove {
        padding-right: 52px;
        display: flex;
        justify-content: flex-end;
        font-size: 26px;
      }
    }
  }
}

.m-comment-container {
  margin-top: rem(40px);

  @include local-mq($until: sm) {
    padding: 0 rem(10px);
  }
}

.cursor-pointer {
  cursor: pointer;
}

// todo review tabs
.m-review-tab {
  .m-average-review {
    @include switzer-bold;
    color: $text-color-grey-600;
    font-size: rem(67px);
    line-height: rem(57px);

    @include local-mq($until: xxl) {
      font-size: rem(60px);
      line-height: rem(55px);
    }
  }

  .m-out-of {
    @include switzer-light;
    font-size: rem(20px);
    color: $text-color-grey-100;
    line-height: rem(16px);
  }

  .m-ratings {
    font-size: rem(37px);

    @include local-mq($until: xxl) {
      font-size: rem(30px);
    }
  }

  .m-rating-order {
    @include switzer-light;
    font-size: rem(24px);
    color: $text-color-grey-100;
  }

  .m-review-progress {
    .ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
      background-color: $yellow-50;
      height: rem(32px);
    }

    .ant-progress.ant-progress-line {
      margin-bottom: rem(10px);

      @include local-mq($until: md) {
        margin: 0;
      }
    }
  }

  .m-write-review {
    padding: rem(26px);
    background-color: $text-color-grey-5;
    width: 100%;
    min-height: 170px;

    > textarea {
      border: 50px;
      resize: none;
      display: flex;
      align-items: center;
      font-size: 20px;

      overflow: hidden;
      background-color: $text-color-grey-5;

      &::placeholder {
        color: $text-color-grey-55;
      }
    }
  }

  .m-submit-review { //todo remove this
    border-radius: 7px;
    padding: 0 15px;

    > button {
      font-size: rem(20px);
    }
  }

  .m-select-rating {
    width: 100%;

    .m-choose-start {
      font-size: rem(36px);
      margin-right: rem(20px);
      cursor: pointer;
    }

    &__error {
      justify-content: center;
      color: red;
      font-size: rem(15px);
      text-align: center;
    }
  }
}

// todo notifications
.m-notifications {
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 30px;
  }

  .m-notifications-title {
    @include switzer-semibold;
    font-size: rem(24px);
    color: $text-color-grey-600;
    padding-left: 10px;
    margin-bottom: 30px;
  }

  .m-notification-category {
    @include switzer-semibold;
    font-size: rem(24px);
    color: $text-color-grey-600;
    margin-left: rem(15px);
  }

  .m-notifications-wrapper {
    padding: 0;

    .m-notification {
      padding: 15px 25px 15px 25px;

      &.not-read {
        background-color: $blue-30;
      }
    }
  }
}

// todo copy episode
.m-copy-episode {
  cursor: pointer;
  width: 300px;

  .m-copy-link {
    padding: 0;
    font-size: rem(24px);
    color: $text-color-grey-100;
  }

  .m-copy-text {
    padding: 0;
    font-size: rem(16px);
    color: $text-color-grey-55;
  }
}

// todo write comments
.m-write-comment-container {
  .m-tab-title {
    margin: rem(20px) 0 rem(25px);
  }

  .m-new-comment {
    position: relative;
    border-radius: 75px;
    min-height: rem(80px);
    border: 1px solid $text-color-grey-25;
    display: flex;
    align-items: center;
    margin: 0 rem(22px);

    @include local-mq($until: extra-wide) {
      margin: 0 rem(22px) 0 0;
      min-height: rem(66px);
    }

    @include local-mq($until: extra-wide) {
      min-height: rem(80px);
    }

    @include local-mq($until: md) {
      min-height: 50px;
    }
  }

  .m-comment-textarea {
    resize: none;

    > textarea {
      border: 50px;
      margin: rem(7px) rem(50px) rem(10px) rem(20px);
      resize: none;
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 0;
      min-height: auto;
      height: rem(23px);
      max-height: rem(53px);
      overflow: hidden;

      @include local-mq($until: extra-wide) {
        font-size: rem(19px);
      }

      @include local-mq($until: md) {
        font-size: rem(16px);
        height: rem(20px);
        margin-top: rem(10px);
      }
    }

    border: 50px;
  }

  .m-comment-post {
    font-size: rem(24px);
    @include switzer-bold;
    color: $blue-main;
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;

    @include local-mq($until: extra-wide) {
      font-size: rem(19px);
    }

    @include local-mq($until: xl) {
      padding-right: rem(35px);
      justify-content: flex-end;
    }

    @include local-mq($until: md) {
      font-size: rem(16px);
    }
  }
}

// todo redirect container
.redirect-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  background-color: $white;

  &__gradient {
    @include parents-gradient-reverse;
    height: 6px;
  }

  &__bold {
    @include switzer-medium;
    color: $text-color-grey-100;
    font-size: rem(17px);
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .w-90 {
    width: 90%;
  }

  &__redirect {
    border: none;
    width: 100%;
    border-radius: 7px;
    white-space: nowrap;

    &:hover {
      opacity: 0.7;
    }

    &.ebs-button--medium .ebs-button {
      @include parents-gradient-reverse;
      font-size: rem(17px);
      border: 2px;
      border-radius: 7px;
    }

    &.w-auto {
      width: auto;
    }
  }

  &__not-now {
    width: auto;
    border: none;

    &.ebs-button--medium .ebs-button {
      @include switzer-medium;
      color: $text-color-grey-100;
      font-size: rem(17px);

      &:hover {
        color: $text-color-grey-100;
        background-color: transparent;
      }
    }

    &.ebs-button--text .ebs-icon {
      fill: $text-color-grey-55;
    }
  }

  &__info {
    color: $text-color-grey-55;
    font-size: rem(16px);
    text-align: center;
    padding: 0 rem(10px);
  }

  .mb-20 {
    margin-bottom: rem(20px);
  }
}

//.watch-margin-left-xl {
//  @include local-mq($until: xl) {
//    margin-left: rem(70px);
//  }
//
//  @include local-mq($until: sm) {
//    margin-left: 0;
//  }
//}

//.watch-margin-x-xl {
//  @include local-mq($until: xl) {
//    margin-left: rem(70px);
//    margin-right: rem(70px);
//  }
//
//  @include local-mq($until: sm) {
//    margin-left: rem(10px);
//    margin-right: rem(10px);
//  }
//}

.my-60 {
  margin: rem(60px) 0;
}

.ant-progress.ant-progress-line {
  margin: 0;
}