.program-card-wide {
  display: flex;
  border-radius: 16px;
  box-shadow: 0 4px 24px $dark-10;
  height: rem(231px);
  background-color: $white;
  margin-bottom: rem(20px);

  .program-card__body__chapter {
    @include text-ellipses(2);
  }

  .events__header {
    .program-card-wide__name {
      @include text-ellipses(2);
      min-height: rem(60px);
    }
  }

  &:hover {
    box-shadow: 0 4px 24px $dark-15;
  }

  &__image {
    img {
      width: rem(387px);
      height: 100%;
      object-fit: cover;
      border-radius: 16px 0 0 16px;
    }
  }

  &__header-border {
    border-bottom: 1px solid $blue-border-color;
    padding-bottom: rem(14px);
  }

  &__header-title {
    display: flex;
    justify-content: space-between;

  }

  &__error-payment {
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      color: $red;
    }
  }

  &__started {
    color: $text-color;
    font-size: rem(14px);
    line-height: rem(18px);
    margin-top: rem(23px);
    display: flex;
    gap: rem(14px);

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__timeline {
    margin-top: rem(25px);

    &__time {
      color: $text-color;
      font-size: rem(14px);
      line-height: rem(18px);
    }

    &__live {
      color: $orange;
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(18px);
    }

    &__send {
      color: $dark-blue;
      width: rem(24px);
      height: rem(24px);
      cursor: pointer;
    }
  }

  &__body {
    position: relative;
    width: 100%;
    padding: rem(20px);
  }

  &__name {
    font-weight: 600;
    font-size: rem(24px);
    line-height: rem(30px);
    color: $dark-blue;
    margin-top: rem(14px);
    @include text-ellipses(1);
  }

  &__description {
    color: $dark-blue;
    font-size: rem(14px);
    line-height: rem(18px);
    margin-top: rem(8px);
    @include text-ellipses(2);
    height: rem(36px);
  }

  &__attributes {
    display: flex;
    width: rem(700px);
    flex-wrap: wrap;
    gap: rem(28px);
  }

  &__attribute {
    width: rem(300px);
    font-size: rem(14px);
    line-height: rem(18px);
    color: $dark-blue;
  }

  &__footer {
    position: absolute;
    width: calc(100% - 40px);
    bottom: rem(18px);

    &__status {
      color: $dark-blue;
      font-size: rem(12px);
      line-height: rem(15px);
      margin-bottom: rem(8px);
    }

    &__attending {
      color: $text-color;
      font-size: rem(12px);
      line-height: rem(15px);
      display: flex;
      //margin-top: rem(9px);
    }

    &__remaining {
      color: $text-color;
      font-weight: 500;
      font-size: rem(14px);
      line-height: rem(18px);
    }
  }
}

@media (max-width: $grid-lg-size + 32) {
  .program-card-wide {
    &__attributes {
      width: auto;
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .my-order-page {
    .program-card-wide {
      padding: rem(16px);
      flex-direction: column;
      height: auto;

      &__body {
        margin-top: 16px;
        padding: 0;
      }

      &__started {
        align-items: center;
      }

      &__name {
        margin-top: 0;
      }

      &__header-border {
        padding-bottom: rem(15px);
      }

      &__image {
        img {
          border-radius: 16px;
          width: 100%;
          height: rem(194px);
        }
      }

      &__attributes {
        gap: rem(16px);
      }

      &__footer {
        position: relative;
        bottom: 0;
        padding: rem(25px 0 11px);
      }
    }
  }
}