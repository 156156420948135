.qa {
  border-bottom: 1px solid $blue-border-color;
  height: rem(60px);

  &__section {
    display: flex;
  }

  &__tab-item {
    line-height: rem(60px);
    height: rem(60px);
    font-size: rem(18px);
    font-weight: 600;
    color: $text-color;
    padding: rem(0 $gutter-size);
    text-align: center;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include local-mq($from: sm) {
      min-width: rem(183px);
    }

    @include local-mq($until: small-tablet) {
      font-size: rem(14.5px);
      padding-left: rem(4px);
      padding-right: rem(4px);
    }

    &.active {
      color: $blue-main;
      border-bottom: 4px solid $blue-main;
    }

    &:hover {
      color: $blue-main;
    }
  }

  &__tabs {
    justify-content: space-between;
  }
}

@include local-mq($until: sm) {
  .qa {
    border-bottom: none;

    &__tabs {
      left: 0;
      justify-content: space-between;
      border-bottom: 1px solid $blue-border-color;

      @include local-mq($until: small-tablet) {
        position: absolute;
      }

      .ebs-space__item {
        width: 100%;
      }
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .qa {
    &__saved-programs {
      display: none;
    }

    &__section {
      justify-content: space-between;
    }

    &__tab-item {
      width: 100%;
    }

    &__tabs {
      .ebs-space__item:nth-child(2) {
        display: none;
      }
    }
  }
}
