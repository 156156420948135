.sign-in-page {
  padding: rem(40px);

  &__head-title {
    margin: rem(0 0 40px);
    justify-content: center;
    font-size: rem(30px);
  }

  &__form-field {
    @include -webkit-autofill();

    .ebs-form__field__label {
      font-family: 'Switzer Light', sans-serif;
      font-size: rem(16px);
      font-weight: 400;
      color: $text-color;
      line-height: rem(24px);
      margin-bottom: rem(11px);
    }

    .ebs-input {
      border: none;
      border-radius: 10px;
      height: rem(48px);
      max-height: rem(48px);
    }

    .ebs-input__wrapper {
      border-radius: 10px;
      border: 1px solid $blue-border-color;
      background-color: $white;

      &--active,
      &--focus {
        border-color: $blue-main;
      }
    }

    &__extra {
      a {
        color: $blue-main;
        font-size: rem(14px);
        font-weight: 600;
        font-family: 'Switzer Regular', sans-serif;
      }

      & + .ebs-form__field__required {
        display: none;
      }
    }

    &.ebs-form__item:not(:last-child) {
      margin-bottom: rem(24px);
    }

  }

  &__form-footer {
    padding: rem(0 6px);
    margin-top: rem(50px);

    .ebs-button__wrapper {
      width: 100%;
      height: rem(50px);
      background-color: $blue-main;
      border-radius: 8px;

      button {
        font-size: rem(20px);
        font-weight: 500;
        height: rem(50px);
      }
    }
  }

  &__head-notification {
    padding: rem(16px 20px);
    background-color: #E9EFFE;
    border-radius: 15px;
    color: $blue-main;
  }

  &__head-notification-icon {
    width: rem(24px);
    height: rem(24px);
    margin-top: rem(6px);
  }

  &__head-notification-text {
    font-weight: 400;
    font-size: rem(18px);
    line-height: rem(30px);

  }
}

@media (max-width: $grid-sm-size + 36) {
  .sign-in-page {
    padding: rem(30px 20px 50px);

    &__head-title {
      text-align: center;
    }

    &__form-footer {
      padding: 0;

      button {
        width: 100%;
      }
    }
  }
}