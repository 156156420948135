.contacts {
  padding: rem(40px);

  &__wrapper {
    border-top: 1px solid $blue-border-color;
  }

  &__need-help {
    .head-title {
      margin-top: rem(10px);
    }
  }

  &__need-help-description {
    font-size: rem(14px);
    line-height: rem(20px);
    font-weight: 400;
    color: $dark-blue;
  }

  &__carrier-details {
    display: flex;
    flex-direction: column;
  }

  &__carrier-full-name {
    color: $dark-blue;
    font-family: 'Switzer Bold', sans-serif;
    font-size: rem(18px);
  }

  &__carrier-job-title {
    color: $quiz-sub-title;
    font-weight: 400;
    font-family: 'Switzer Light', sans-serif;
    font-size: rem(14px);
  }

  &__carrier-photo img {
    border-radius: 100%;
    width: rem(64px);
    height: rem(64px);
    object-fit: cover;
  }

  &__carrier-contact {
    &__item, a {
      color: $dark-blue;
      font-size: rem(14px);
      line-height: rem(28px);
    }

    a {
      cursor: pointer;
    }

    svg {
      margin-top: rem(8px);
    }
  }
}


@media (max-width: $grid-sm-size + 36) {
  .contacts {
    flex-direction: column;
    text-align: left;
    padding: rem(20px);
    gap: rem(28px);

    &__wrapper {

    }
  }
}