.comments {
  position: relative;
  padding: rem(30px 24px 0);

  &__head-title {
    color: $dark-blue;
    font-size: rem(14px);
  }

  &__list {
    margin-top: rem(24px);
    border-top: 1px solid $blue-border-color;
    padding-top: rem(16px);
  }

  &__sorting {
    font-size: rem(14px);
    @include switzer-regular;
    margin-bottom: rem(24px);
    display: flex;
    gap: rem(2px);

    &__selected {
      color: $dark-blue;
      display: flex;
      align-items: center;
      gap: rem(2px);
      cursor: pointer;

      svg {
        width: rem(9px);
        height: rem(9px);
      }
    }

    &__options {
      display: flex;
      flex-direction: column;
      color: $dark-blue;
      gap: rem(5px);
      font-size: rem(14px);

      span {
        cursor: pointer;
      }
    }
  }

  &__search {
    .ebs-input__wrapper {
      border-radius: rem(10px);
      background-color: rgba(223, 225, 232, 0.4);

      input.ebs-input {
        color: #99a0b4;
        font-size: rem(17px);
      }
    }

    svg.ebs-icon--search {
      color: #99a0b4;
    }
  }
}

.write-comment {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: calc(100% + #{rem(48px)});
  background-color: #fff;
  z-index: 2;
  gap: rem(24px);
  margin: rem(0 -24px);
  padding: rem(24px);
  border-radius: rem(16px);
  box-shadow: 0 -8px 12px rgba(5, 41, 65, 0.1);
  transition: all 300ms;

  &__avatar .ebs-avatar {
    width: rem(36px);
    height: rem(36px);
  }

  &__text-area {
    width: 100%;

    .has__more-value textarea.has__value {
      min-height: rem(100px);
    }

    textarea {
      width: 100%;
      max-height: rem(200px);
      height: rem(36px);
      min-height: rem(36px);
      border-radius: rem(40px);
      font-size: rem(14px);
      line-height: rem(26px);
      padding: rem(3px 16px);
      resize: vertical;

      &.has__value {
        min-height: rem(55px);
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }

  &__post {
    color: $blue-main;
    @include switzer-semibold;
    font-size: rem(14px);
    line-height: rem(36px);
    cursor: pointer;
  }
}

.edit-comment {
  &__text-area {
    width: 100%;

    &.has__more-value textarea.has__value {
      min-height: rem(100px);
    }

    textarea {
      width: 100%;
      height: rem(36px);
      min-height: rem(36px);
      font-size: rem(14px);
      line-height: rem(26px);
      padding: rem(3px 16px);
      resize: vertical;

      &.has__value {
        min-height: rem(55px);
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: rem(10px);
  }
}

.comment {
  margin-bottom: rem(16px);
  display: flex;
  gap: rem(12px);
  justify-content: space-between;
  align-items: flex-start;

  &__avatar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: rem(14px);

    &.is-admin {
      border: rem(1px) solid $blue-main;
      padding: rem(1px);
      box-sizing: border-box;
      border-radius: 100%;

      .ebs-avatar {
        width: rem(32px);
        height: rem(32px);
      }
    }

    .ebs-avatar {
      width: rem(36px);
      height: rem(36px);
    }

    &__full-name {
      @include switzer-regular;
      line-height: rem(16px);
      font-size: rem(14px);
      margin-bottom: rem(4px);
      color: $dark-blue;
    }

    &__created-at {
      @include switzer-light;
      font-size: rem(11px);
      line-height: rem(14px);
    }

    &__position {
      @include switzer-light;
      font-size: rem(12px);
      color: $blue-main;
    }
  }

  &__body {
    width: 100%;

    &__text-wrapper {
      background-color: $bg-blue-40;
      border-radius: 8px;
      padding: rem(12px);
      position: relative;

      &.bordered::before {
        content: ' ';
        width: rem(30px);
        height: calc(100% + 4.7rem);
        border-left: 1px solid $blue-border-color;
        border-bottom: 1px solid $blue-border-color;
        border-radius: 0 0 0 16px;
        position: absolute;
        left: rem(-30px);
        z-index: -1;
      }
    }

    &__text {
      line-height: rem(18px);
      font-size: rem(14px);
      color: $dark-blue;
      @include switzer-regular;

      &:not(.read-more) {
        @include text-ellipses(3);
      }

      &__read-more {
        font-size: rem(12px);
        @include switzer-medium;
        margin-top: rem(4px);
        cursor: pointer;
      }
    }
  }

  &__footer {
    margin-top: rem(12px);

    &__reaction {
      display: flex;
      gap: rem(6px);
      justify-content: flex-start;
      align-items: center;

      svg {
        cursor: pointer;
        fill: $white;
        color: $text-color;
        width: rem(10px);
        height: rem(10px);

        &.liked {
          fill: $dark-blue;
          color: $dark-blue;
        }
      }
    }

    &__reply {
      @include switzer-semibold;
      font-size: rem(14px);
      line-height: rem(18px);
      color: #737373;
    }

    &__time {
      @include switzer-semibold;
      font-size: rem(14px);
    }
  }

  &__children {
    padding: rem(29px 0 8px 0);

    .write-comment {
      margin-top: 0;
    }

    .comment {
      &:last-child {
        .comment__body {
          &__text-wrapper {
            &::before {
              display: none;
            }
          }
        }
      }

      &__body {
        &__text-wrapper {
          &::before {
            content: ' ';
            height: calc(100% + 64px);
            border-left: 1px solid $blue-border-color;
            position: absolute;
            left: rem(-30px);
            z-index: -1;
          }
        }
      }
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .comments {
    padding: rem(30px 0 0);
  }
  .comment {
    &__body {
      &__text-wrapper {
        &.bordered {
          &::before {
            height: calc(100% + 4.7rem);
          }
        }
      }
    }
  }
}
