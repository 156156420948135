.qa-item {
  display: flex;
  border: none;
  border-radius: rem($gutter-size);
  background-color: transparent;

  &__ages, &__tags {
    max-height: rem(34px);
    overflow: hidden;

    @include local-mq($from: qhd) {
      max-height: rem(32px);
    }
  }

  &__image {
    cursor: pointer;
    min-height: 100%;
    min-width: rem(312px);
    background-size: cover;
    background-position: center;
  }

  .head-title {
    margin-top: rem(0);
    margin-bottom: 0;
  }

  .ebs-space__item {
    height: 100%;
    font-size: rem(14px);
  }

  .ebs-card__body {
    width: 100%;
    background-color: #fff;
    padding-top: rem(20px);

    > .ebs-space > .ebs-space__item {
      width: 100%;
    }
  }

  .ebs-chips {
    @include flex-center;
    pointer-events: none;
    color: $blue-main;
    font-size: rem(14px);
    font-weight: 500;
    height: rem(25px);
    padding: rem(3.5px 10px);
    background-color: #f2f3f6;
    border: 1px solid #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      color: $dark-blue;
      border: 1px solid $dark-blue;
      background-color: #fff;
    }
  }

  .ebs-button {
    white-space: nowrap;
    font-weight: 600;
    font-size: rem(14px);
  }

  .ebs-checkbox__wrapper {
    .ebs-checkbox {
      width: rem(17px);
      height: rem(17px);

      &__text {
        color: $dark-blue;
        padding-left: rem(29px);
      }
    }
  }

  @include local-mq($until: lg) {
    flex-direction: column;

    .qa-item__image {
      height: rem(191px);
      margin-bottom: rem(-15px);
    }

    .ebs-card__body {
      border-radius: rem($gutter-size);
      padding: rem(20px 16px);
      overflow: hidden;
    }

    .head-title {
      margin: 0;
      line-height: rem(23px);
      font-size: rem(18px);
    }
  }
}
