.breadcrumbs {
  margin-top: rem(30px);
  margin-bottom: rem(36px);

  &__item {
    display: inline;

    &__link {
      color: $text-color;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child &__link,
    &:last-child &__label {
      color: $dark-blue;
      font-weight: 600;
    }
  }

  &__separator {
    color: $text-color;
    padding: rem(0 4px);
  }
}

@media (max-width: $grid-sm-size + 36) {
  .breadcrumbs {
    display: none;
  }
}