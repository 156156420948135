.overview {
  margin-bottom: rem(70px);

  &__title {
    font-size: rem(18px);
    line-height: rem(24px);
    margin-bottom: 12px;
  }

  &__program-overview {
    width: 100%;
    box-shadow: 0 4px 24px $dark-10;
    border-radius: 16px;
    padding: rem(18px 18px);

    &__item {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    &__item-data {
      color: $blue-main;
      font-size: rem(30px);
      @include switzer-semibold;
    }

    &__item-label {
      font-size: rem(12px);
      text-transform: lowercase;
    }

    &__description {
      margin: rem(36px 0);

    }

    &__description-label {
      @include switzer-semibold;
      font-size: rem(18px);
      line-height: rem(24px);
      color: $dark-blue;
    }

    &__description-text {
      color: $dark-blue;
      font-size: rem(14px);
      line-height: rem(18px);
      margin-top: rem(12px);
    }
  }
}

@media (max-width: $grid-sm-size + 36) {
  .overview {
    &__title {
      display: none;
    }

    &__program-overview {
      margin-top: rem(24px);

      &__description {
        margin-top: rem(24px);
      }

      &__description-text {
        font-size: rem(14px);
        line-height: rem(18px);
      }
    }

  }
}
