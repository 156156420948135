.episodes {
  margin-bottom: rem(20px);

  &__item {
    display: flex;
    justify-content: space-between;
    padding: rem(10px 14px);
    border-radius: rem(12px);
    gap: rem(10px);

    &.active {
      background-color: #f1f3fa;
    }

    &-description {
      color: $dark-blue;
      font-size: 12px;
      line-height: 18px;
    }

    &-unlock {
      color: $blue-main;
      font-weight: 500;
      font-size: rem(12px);
    }

    &-access {
      background-size: cover !important;
      background-position: center !important;
      border-radius: rem(10px 10px);
      width: rem(80px);
      height: rem(55px);

      &.has-progress {
        border-radius: rem(10px 10px 0px 0px);
      }

      &__cover {
        @include flex-center;
        position: relative;
        height: 100%;
        font-size: rem(18px);
        color: #fff;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        border-radius: 10px;

        &-play {
          @include flex-center;
          background-color: $blue-main;
          border: 1.5px solid $blue-main;
          border-radius: 100%;
          font-size: rem(10px);
          width: rem(20px);
          height: rem(20px);
        }

        &-duration,
        &-progress {
          position: absolute;
          left: 0;
          bottom: 0;
          height: rem(3px);
          width: 100%;
        }

        &-duration {
          background-color: $blue-border-color;
        }

        &-progress {
          z-index: 2;
          background-color: $blue-main;
        }
      }
    }

    &__title {
      a {
        @include switzer-semibold;
        font-size: rem(14px);
        line-height: rem(18px);
        color: $dark-blue;
        padding-right: rem(10px);
      }
    }

    &__subtitle {
      font-size: rem(12px);
    }

    &__bookmark {
      width: rem(24px);
      height: rem(24px);
      color: $dark-blue;
      cursor: pointer;

      &.bookmarked {
        fill: $dark-blue;

        path:nth-child(n + 2) {
          fill: $white;
          color: $white;
        }
      }
    }
  }
}
