.card-container {
  display: flex;
  justify-content: center;

  &__wrapper {
    width: 1200px;

    @include local-mq($until: wide) {
      width: 1000px;
    }

    @include local-mq($until: lg) {
      width: 700px;
    }

    @include local-mq($until: small-desktop) {
      width: 90%;
    }

    @include local-mq($until: sm) {
      width: 390px;
    }

    @include local-mq($until: small-tablet) {
      width: calc(100% - 32px);
    }
  }
}
