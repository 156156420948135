.drop-zone {
  border-radius: 10px;
  border: 1px dashed $dark-10;
  background-color: $white;
  height: rem(150px);
  text-align: center;
  color: $quiz-sub-title;
  font-weight: 600;
  font-size: rem(14px);
  line-height: rem(18px);

  &__wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    gap: rem(16px);
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &__link {
    font-weight: 600;
    font-size: rem(14px);
    color: $blue-main;
    cursor: pointer;
  }
}
